<template>
  <b-row>
    <b-col>
      <h5 class="font-weight-bolder cursor-pointer" @click="collapseThresholds = !collapseThresholds">
        <font-awesome-icon :icon="collapseThresholds ? 'angle-right' : 'angle-down'" />
        Thresholds
      </h5>
      <b-collapse :visible="!collapseThresholds">
        <b-row>
          <b-col>
            <TooltippedText
              value="Threshold determining the article confidence score required to allow generation of reply using GenAI."
            />
            Answer generation confidence: {{ articleConfidenceThreshold }}%
            <b-form-input
              :value="articleConfidenceThreshold"
              type="range"
              min="0"
              max="100"
              size="sm"
              @input="v=>articleConfidenceThreshold = parseInt(v, 10)"
            />
          </b-col>
          <b-col>
            <TooltippedText
              value="Threshold determining the article confidence score required to present a list of articles if an answer was not generated."
            />
            Article presentation confidence: {{ articlePresentationThreshold }}%
            <b-form-input
              :value="articlePresentationThreshold"
              type="range"
              min="0"
              max="100"
              size="sm"
              @input="v=>articlePresentationThreshold = parseInt(v, 10)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <TooltippedText
              value="Guard using GenAI evaluation of how relevant the response is to the question."
            />
            Response Relevance Guard: {{ answerEvaluationThreshold }}%
            <b-form-input
              :value="answerEvaluationThreshold"
              type="range"
              min="0"
              max="100"
              size="sm"
              @input="v=>answerEvaluationThreshold = parseInt(v, 10)"
            />
          </b-col>
          <b-col>
            <TooltippedText
              value="Guard using GenAI evaluation of how much the response is grounded in the
               article context."
            />
            Article Groundedness Guard: {{ groundednessThreshold }}%
            <b-form-input
              :value="groundednessThreshold"
              type="range"
              min="0"
              max="100"
              size="sm"
              @input="v=>groundednessThreshold = parseInt(v, 10)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <TooltippedText
              value="Guard using word overlap between response and article context."
            />
            Word Similarity Guard: {{ jaccardGuardingThreshold }}%
            <b-form-input
              :value="jaccardGuardingThreshold"
              type="range"
              min="0"
              max="100"
              size="sm"
              @input="v=>jaccardGuardingThreshold = parseInt(v, 10)"
            />
          </b-col>
          <b-col>
            <TooltippedText
              value="Guard using semantic similarity between response and article context."
            />
            Semantic Similarity Guard: {{ embeddingGuardingThreshold }}%
            <b-form-input
              :value="embeddingGuardingThreshold"
              type="range"
              min="0"
              max="100"
              size="sm"
              @input="v=>embeddingGuardingThreshold = parseInt(v, 10)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <DebounceButton
              v-b-tooltip.hover.noninteractive.viewport="'Set thresholds based on value of the first activity in the table below'"
              size="sm"
              block
              variant="primary"
              text="Copy threshold values from bot"
              @click="copyThresholds()"
            />
          </b-col>
          <b-col class="text-right">
            <DebounceButton
              size="sm"
              block
              variant="primary"
              text="Save thresholds"
              @click="saveThresholds()"
            />
            <small v-if="unsavedChanges" class="text-warning">*Unsaved changes</small>
          </b-col>
        </b-row>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import TooltippedText from '@/components/TooltippedText.vue';
import DebounceButton from 'supwiz/components/DebounceButton.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'AiThresholds',
  components: { TooltippedText, DebounceButton },
  props: {
    firstActivity: {
      type: Object,
      required: true,
    },
    firstRankerActivity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collapseThresholds: true,
      localDataCopy: null,
    };
  },
  computed: {
    ...mapState('health', ['aiThresholds']),
    unsavedChanges() {
      return JSON.stringify(this.localDataCopy) !== JSON.stringify(this.aiThresholds);
    },
    articleConfidenceThreshold: {
      get() {
        if (this.aiThresholds.articleConfidenceThreshold !== undefined) {
          return this.aiThresholds.articleConfidenceThreshold;
        }
        return 0;
      },
      set(value) {
        this.setAiThresholds({ key: 'articleConfidenceThreshold', value });
      },
    },
    articlePresentationThreshold: {
      get() {
        if (this.aiThresholds.articlePresentationThreshold !== undefined) {
          return this.aiThresholds.articlePresentationThreshold;
        }
        return 0;
      },
      set(value) {
        this.setAiThresholds({ key: 'articlePresentationThreshold', value });
      },
    },
    answerEvaluationThreshold: {
      get() {
        if (this.aiThresholds.answerEvaluationThreshold !== undefined) {
          return this.aiThresholds.answerEvaluationThreshold;
        }
        return 0;
      },
      set(value) {
        this.setAiThresholds({ key: 'answerEvaluationThreshold', value });
      },
    },
    groundednessThreshold: {
      get() {
        if (this.aiThresholds.groundednessThreshold !== undefined) {
          return this.aiThresholds.groundednessThreshold;
        }
        return 0;
      },
      set(value) {
        this.setAiThresholds({ key: 'groundednessThreshold', value });
      },
    },
    jaccardGuardingThreshold: {
      get() {
        if (this.aiThresholds.jaccardGuardingThreshold !== undefined) {
          return this.aiThresholds.jaccardGuardingThreshold;
        }
        return 0;
      },
      set(value) {
        this.setAiThresholds({ key: 'jaccardGuardingThreshold', value });
      },
    },
    embeddingGuardingThreshold: {
      get() {
        if (this.aiThresholds.embeddingGuardingThreshold !== undefined) {
          return this.aiThresholds.embeddingGuardingThreshold;
        }
        return 0;
      },
      set(value) {
        this.setAiThresholds({ key: 'embeddingGuardingThreshold', value });
      },
    },
  },
  watch: {
    unsavedChanges(newVal) {
      this.$emit('unsavedChanges', newVal);
    },
  },
  async created() {
    await this.fetchHealthConfig();
    this.localDataCopy = cloneDeep(this.aiThresholds);
  },
  methods: {
    ...mapMutations('health', ['setAiThresholds', 'copyActivityThresholds']),
    ...mapActions('health', ['saveAiThresholds', 'fetchHealthConfig']),
    copyThresholds() {
      const thresholds = {
        articleConfidenceThreshold: this.firstRankerActivity.payload?.replyArticleThreshold,
        articlePresentationThreshold: this.firstRankerActivity.payload?.linksArticleThreshold,
        answerEvaluationThreshold: this.firstActivity.payload?.replyAnswerRelevanceThreshold,
        groundednessThreshold: this.firstActivity.payload?.replyGptGroundednessThreshold,
        jaccardGuardingThreshold: this.firstActivity.payload?.replyJaccardSimThreshold,
        embeddingGuardingThreshold: this.firstActivity.payload?.replyEmbeddingSimThreshold,
      };
      this.copyActivityThresholds({ thresholds });
    },
    saveThresholds() {
      this.saveAiThresholds();
      this.localDataCopy = cloneDeep(this.aiThresholds);
    },
  },
};
</script>
