<template>
  <div>
    <h3>Subflow Variables</h3>
    <b-card
      no-body
    >
      <p>
        Variables set for subflows only.
      </p>
    </b-card>
    <hr>
    <b-row>
      <b-col
        cols="12"
      >
        <h4>
          Subflow input variables
        </h4>
        <b-card
          no-body
        >
          <p class="mb-2">
            Here you must declare and assign default values for subflow input-variables.
          </p>
          <p class="mb-2">
            Subflow input-variables allow you to pass information from main-flow to
            subflow.
          </p>
          <p>
            You must provide a default value for each subflow input-variable, that will be used in
            case
            mainflow does not provide a value for it.
          </p>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      class="mb-2 mt-2"
    >
      <b-col cols="6">
        <b-button
          variant="primary"
          @click="addSubFlowVariable"
        >
          <font-awesome-icon
            icon="plus"
          />
          New input variable
        </b-button>
      </b-col>
    </b-row>

    <template
      v-for="(subvar, index) in subFlowInputVariables"
    >
      <b-row
        :key="'sfvar_' + index"
        class="mb-3 mt-3"
      >
        <b-col
          :cols="subvar.value.length > 40 ? 12 : 6"
          class="mt-1"
        >
          <label>
            Variable name:
          </label>
          <VariableName
            :value="subvar.name"
            placeholder="Subflow input variable name"
            @input="value => onChangeVariableName('input', value, index)"
          />
        </b-col>
        <b-col
          :cols="subvar.value.length > 40 ? 12 : 6"
          class="mt-1"
        >
          <label>
            Variable code:
          </label>

          <b-form-textarea
            v-if="subvar.value.length > 40"
            :ref="'textarea' + index"
            :value="subvar.value"
            :state="$v.subFlowInputVariables.$each[index]['value'].$invalid ? false : null"
            type="text"
            placeholder="Default value (BotScript)"
            class="text-monospace"
            @blur="checkFocus(index, false)"
            @input="e => onChangeDefaultVarValue(e, index)"
          />
          <b-form-input
            v-else
            :ref="'input' + index"
            :value="subvar.value"
            :state="$v.subFlowInputVariables.$each[index]['value'].$invalid ? false : null"
            type="text"
            placeholder="Default value (BotScript)"
            class="text-monospace"
            @blur="checkFocus(index, true)"
            @input="e => onChangeDefaultVarValue(e, index)"
          />
        </b-col>
      </b-row>

      <b-row
        :key="'sfvar_description_' + index"
        class="mt-1"
      >
        <b-col>
          <b-form-input
            :value="subvar.description"
            type="text"
            placeholder="Description"
            @change="e => onChangeDescription(e, index)"
          />
        </b-col>
        <b-col
          cols="auto"
        >
          <b-btn
            class="ml-auto"
            variant="outline-danger"
            @click="openInputRemoveModal(index)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-btn>
        </b-col>
      </b-row>
    </template>

    <b-row
      class="mt-3"
    >
      <b-col
        cols="12"
      >
        <h4>
          Subflow output variables
        </h4>
        <b-card
          no-body
        >
          <p class="mb-2">
            Below you can declare subflow output-variables.
          </p>
          <p class="mb-2">
            Subflow output-variables are variables that you want to expose outside subflow -
            for your mainflow to use <em> after </em> subflow is run.
          </p>
          <p>
            You can declare additional variables in your subflow as per-usual, but only
            those variables that are listed here will be available for main-bot to consume.
          </p>
        </b-card>
      </b-col>
    </b-row>

    <b-row
      class="mb-2 mt-2"
    >
      <b-col cols="6">
        <b-button
          variant="primary"
          @click="addOutputVariableProxy"
        >
          <font-awesome-icon
            icon="plus"
          />
          New output variable
        </b-button>
      </b-col>
    </b-row>

    <b-row
      v-for="(variableName, index) in subflowOutputVariables"
      :key="variableName"
      class="mt-2"
    >
      <b-col>
        <label>
          Variable name:
        </label>
        <VariableName
          :value="variableName"
          placeholder="Subflow output variable name"
          :already-used-variable-names="theOtherOutputVariableNames(index)"
          @input="newValue => onChangeVariableName('output', newValue, index)"
        />
      </b-col>
      <b-col
        cols="auto"
        style="margin-top:1.9rem"
      >
        <b-btn
          variant="outline-danger"
          @click="deleteOutputVariableProxy(variableName)"
        >
          <font-awesome-icon icon="trash-alt" />
        </b-btn>
      </b-col>
    </b-row>

    <delete-subflow-variable-modal
      :subflow-variable-type="toDeleteVariableType"
      :subflow-variable-name="toDeleteVariableName"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import VariableName from '@/components/VariableName.vue';
import DeleteSubflowVariableModal from '@/pages/BotConfig/DeleteSubflowVariableModal.vue';

export default {
  name: 'SubflowVariables',
  components: {
    VariableName,
    DeleteSubflowVariableModal,
  },
  mixins: [validationMixin],
  data() {
    return {
      toDeleteVariableName: '',
      toDeleteVariableType: '',
      caretPosition: 0,
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getOutputVariables',
      'getInputVariables',
    ]),
    subFlowInputVariables() {
      return this.getInputVariables;
    },
    subflowOutputVariables() {
      return this.getOutputVariables;
    },
  },
  methods: {
    ...mapMutations('botManipulation/activeBot/config', [
      'setSubflowInputVariable',
      'addOutputVariable',
      'updateOutputVariable',
    ]),
    getVariableName(index) {
      if (index < 0 || index >= this.subFlowInputVariables.length) {
        return '';
      }
      return this.subFlowInputVariables[index].name;
    },
    openInputRemoveModal(index) {
      // Prepare props passed to modal
      this.toDeleteVariableName = this.getVariableName(index);
      this.toDeleteVariableType = 'input';
      this.$bvModal.show('delete-subflow-variable-modal');
    },
    removeSubFlowVar(index) {
      this.setSubflowInputVariable({ index });
    },
    onChangeVariableName(variableType, newName, index) {
      // Verify that variable-name is valid
      if (variableType === 'output') {
        this.updateOutputVariable({ index, newName });
      } else if (variableType === 'input') {
        const variable = { ...this.subFlowInputVariables[index], name: newName };
        this.setSubflowInputVariable({ index, variable });
      }
    },
    onChangeDefaultVarValue(value, index) {
      if (!this.$refs[`input${index}`].length) {
        this.caretPosition = this.$refs[`textarea${index}`][0].selectionStart;
      } else {
        this.caretPosition = this.$refs[`input${index}`][0].selectionStart;
      }
      const variable = { ...this.subFlowInputVariables[index], value };
      this.setSubflowInputVariable({ index, variable });
    },
    onChangeDescription(description, index) {
      const variable = { ...this.subFlowInputVariables[index], description };
      this.setSubflowInputVariable({ index, variable });
    },
    addSubFlowVariable() {
      const indexToInsertIn = this.subFlowInputVariables.length;
      this.setSubflowInputVariable({
        index: indexToInsertIn,
        variable: {
          id: 'variable',
          name: `subvar_${indexToInsertIn}`,
          value: '',
          description: '',
          default: '',
        },
      });
    },
    addOutputVariableProxy() {
      let count = 1;
      let unusedPlaceholderName = `output_variable_${count}`;
      while (this.getOutputVariables.includes(unusedPlaceholderName)) {
        count += 1;
        unusedPlaceholderName = `output_variable_${count}`;
      }
      this.addOutputVariable(unusedPlaceholderName);
    },
    deleteOutputVariableProxy(variableName) {
      // Before presenting modal, prepare the state that is passed to delete-modal
      this.toDeleteVariableType = 'output';
      this.toDeleteVariableName = variableName;

      // Present modal
      this.$bvModal.show('delete-subflow-variable-modal');
    },
    theOtherOutputVariableNames(index) {
      const theCopy = [...this.subflowOutputVariables];
      theCopy.splice(index, 1); // Splice mutates array in-place
      return theCopy;
    },
    checkFocus(index, isExpanding) {
      this.$nextTick(() => {
        if (isExpanding) {
          if (!this.$refs[`input${index}`].length) {
            this.$refs[`textarea${index}`][0].focus();
            this.$refs[`textarea${index}`][0].setSelectionRange(this.caretPosition, this.caretPosition);
          }
        } else if (!this.$refs[`textarea${index}`].length) {
          this.$refs[`input${index}`][0].focus();
          this.$refs[`input${index}`][0].setSelectionRange(this.caretPosition, this.caretPosition);
        }
      });
    },
  },
  validations: {
    subFlowInputVariables: {
      $each: {
        value: {
          required,
        },
      },
    },
  },
};
</script>
