<template>
  <b-container fluid class="p-0">
    <b-list-group>
      <b-list-group-item
        v-if="totalFinishedAiTests !== totalRunningAiTests"
        variant="info"
        class="r-25 health-alert"
      >
        <b-row class="mx-0 py-2 h-100">
          <b-col class="my-auto">
            <h4 class="my-auto">
              Running tests...
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        v-else
        :variant="getAlertVariant"
      >
        <b-row
          class="mx-0"
          role="tablist"
          align-h="between"
        >
          <b-col
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Run tests"
              class="text-secondary"
              variant="link"
              @click="runAiTestsProxy"
            >
              <font-awesome-icon
                icon="redo"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col
            v-if="getRunningAiTests"
            cols="*"
            class="my-auto"
          >
            <b-button
              v-b-tooltip.hover
              title="Clear testrun"
              class="text-secondary"
              variant="link"
              @click="clearAiTests"
            >
              <font-awesome-icon
                icon="eraser"
                size="lg"
              />
            </b-button>
          </b-col>
          <b-col class="py-2">
            <h4
              v-if="totalFinishedAiTests === totalRunningAiTests && totalFailedAiTests === 0"
              class="my-1"
            >
              Finished {{ totalFinishedAiTests }} out of
              {{ totalRunningAiTests }} tests ({{ totalFailedAiTests }} failures).
            </h4>
            <h4
              v-else-if="totalFinishedAiTests === totalRunningAiTests && totalFailedAiTests > 0"
              class="my-1"
            >
              Finished {{ totalFinishedAiTests }} out of
              {{ totalRunningAiTests }} tests ({{ totalFailedAiTests }} failures).
            </h4>
            <h4
              v-else-if="getAiTests.length === 0"
              class="my-1"
              variant="info"
            >
              There are no tests to run
            </h4>
            <h4
              v-else
              class="my-1"
              variant="info"
            >
              Run the {{ getAiTests.length > 1 ? 'tests' : 'test' }} to see results.
            </h4>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>

    <!-- <b-btn
          v-b-tooltip.hover.noninteractive
          size="sm"
          class="px-3"
          variant="primary"
          title="Export tests to Excel"
          @click="exportTests"
        >
          <font-awesome-icon
            icon="download"
          />
        </b-btn> -->

    <div class="my-2 border px-3 pt-3 pb-2">
      <b-row>
        <b-col>
          <h5 class="font-weight-bolder">
            Evaluation
          </h5>
          <div v-if="missingReview > 0" class="mb-2">
            <span class="list-group-item-secondary px-2 r-25 mr-1">
              {{ missingReview }}
              <font-awesome-icon icon="exclamation-circle" />
            </span>
            Missing reviews, for optimal evaluation provide feedback to all generated replies.
          </div>
          <b-row>
            <b-col>
              <span class="d-block mb-1 font-weight-bolder">Generated reply quality:</span>
              <span class="list-group-item-success px-2 r-25 mr-1">{{ goodReplies }}</span>
              Good replies<br>
              <span class="list-group-item-warning px-2 r-25 mr-1">{{ acceptableReplies }}</span>
              Acceptable replies<br>
              <span class="list-group-item-danger px-2 r-25 mr-1">{{ unacceptableReplies }}</span>
              Unacceptable replies<br>
              <span class="list-group-item-primary px-2 r-25 mr-1">{{ noReplySent.length }}</span>
              No reply sent<br>
            </b-col>
            <b-col>
              <span class="d-block my-1 font-weight-bolder">Articles presented:</span>
              <span class="list-group-item-success px-2 r-25 mr-1">{{ correctArticlesIncluded }}</span>
              Correct article included<br>
              <span class="list-group-item-warning px-2 r-25 mr-1">{{ correctArticlesNotIncluded }}</span>
              Correct article not included<br>
              <span class="list-group-item-primary px-2 r-25 mr-1">{{ noArticleSent }}</span>
              No articles sent
            </b-col>
          </b-row>
          <hr>
          <span style="font-size: 1.2rem;">

            <span class="my-1 font-weight-bolder mr-3">Overall answered:</span>
            <span class="list-group-item-success px-2 r-25 mr-1">{{ overallSuccess }}</span>|
            <span class="list-group-item-warning px-2 r-25 mr-1">{{ overallWarning }}</span>|
            <span class="list-group-item-danger px-2 r-25 mr-1">{{ overallDanger }}</span>
          </span>
        </b-col>
      </b-row>
      <hr>
      <AiThresholds :first-activity="firstActivity" :first-ranker-activity="firstRankerActivity" />
    </div>

    <div style="width: 100%; overflow-x: auto;" class="px-3">
      <b-row style="min-width: 1200px;">
        <b-col cols="auto" style="width: 50px" class="header-col" />
        <b-col cols="auto" style="width: 10%" class="header-col">
          Name
        </b-col>
        <b-col cols="auto" style="width: calc(90% - 50px);">
          <b-row>
            <b-col cols="auto" style="width: 18%;" class="header-col">
              User Question/Search Query
            </b-col>
            <b-col cols="auto" style="width: 18%;" class="header-col">
              AI Answer/Was reply sent?
            </b-col>
            <b-col cols="auto" style="width: 26%;" class="header-col">
              Predicted Articles
              (title, was used, score)
            </b-col>
            <b-col cols="auto" style="width: 10%;" class="header-col">
              Reply Guarding
            </b-col>
            <b-col cols="auto" style="width: 28%;" class="header-col">
              Manual Review
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row
        v-if="!aiTests.length"
        style="min-width: 1200px;"
        class="border p-3"
      >
        <b-col>
          There are no items to show.
        </b-col>
      </b-row>
      <template v-else>
        <b-row
          v-for="(test, testIndex) in aiTests"
          :key="`test-${testIndex}`"
          style="min-width: 1200px;"
        >
          <b-col>
            <b-row>
              <b-col cols="auto" style="width: 50px" class="header-col">
                <b-button-group vertical>
                  <b-btn
                    variant="outline-danger"
                    size="sm"
                    @click.stop="deleteClicked(testIndex)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </b-btn>
                  <b-btn
                    variant="outline-primary"
                    size="sm"
                    @click.stop="runSpecificAiTest(testIndex)"
                  >
                    <font-awesome-icon icon="redo" />
                  </b-btn>
                  <b-btn
                    variant="outline-primary"
                    size="sm"
                    @click.stop="rowClicked(test, testIndex)"
                  >
                    <font-awesome-icon icon="external-link-alt" />
                  </b-btn>
                </b-button-group>
              </b-col>
              <b-col
                cols="auto"
                style="width: 10%;"
                class="grid-col py-auto align-items-center"
              >
                {{ test.name }}
                <template v-if="!test.running">
                  <div
                    v-if="test.failed"
                    v-b-popover.hover.top="test.failed ? test.failedMsg : false"
                    title="Failure reason"
                    class="text-center r-25 px-1 list-group-item-danger"
                  >
                    Failed
                    <font-awesome-icon icon="info-circle" />
                  </div>
                </template>
              </b-col>
              <b-col v-if="test.running" cols="auto" style="width: calc(90% - 50px);" class="text-right grid-col py-auto align-items-center">
                <font-awesome-icon icon="spinner" spin />
              </b-col>
              <b-col v-else-if="!test.hasData && test.failed !== true" cols="auto" style="width: calc(90% - 50px);" class="grid-col py-auto align-items-center">
                Run test to see results.
              </b-col>
              <b-col v-else-if="!test.activities.length" cols="auto" style="width: calc(90% - 50px);" class="grid-col py-auto align-items-center">
                There are no activities to show.
              </b-col>
              <b-col v-else cols="auto" style="width: calc(90% - 50px);">
                <b-row
                  v-for="(activity, activityIndex) in test.activities"
                  :key="`activity-${activityIndex}`"
                >
                  <b-col cols="auto" style="width: 18%;" class="grid-col" :class="activityIndex === 0 ? 'min-height-col' : ''">
                    <small class="text-muted">User Question<br></small>
                    <span>{{ activity.userQuestion }}</span><br>
                    <span
                      v-if="isDifferent(activity)"
                    >
                      <small class="text-muted">Search Query<br></small>
                      {{ activity.result.query }}</span>

                    <div
                      class="r-25 px-1 list-group-item-primary w-100 mb-1 text-center"
                      :class="getReviewState(activity, false)"
                    >
                      {{ getReviewState(activity, true) }}
                    </div>
                  </b-col>
                  <b-col cols="auto" style="width: 18%;" class="grid-col">
                    {{ activity.result ? activity.result.answer : '-' }}

                    <template v-if="!test.running">
                      <div
                        v-if="!activity.reply_sent"
                        v-b-popover.hover.top="activity.reply_not_sent_reason"
                        title="Not sent reason"
                        class="text-center r-25 px-1 list-group-item-secondary"
                      >
                        No reply generated
                        <font-awesome-icon icon="info-circle" />
                      </div>
                    </template>
                  </b-col>
                  <b-col cols="auto" style="width: 26%;" class="grid-col p-0">
                    <div v-if="!activity.articles.length" class="px-3 pt-2">
                      No article predictions found.
                    </div>
                    <b-list-group v-else flush>
                      <b-list-group-item
                        v-for="(article, articleId) in activity.articles"
                        :key="articleId"
                        class="p-2 bg-transparent"
                      >
                        <b-row no-gutters class="d-flex justify-content-between">
                          <b-col>
                            {{ article.title || (activity.payload.contextType === 'custom' ? activity.payload.customContext : '-') }}
                            <span
                              v-if="article.link || article.light_viewer_link"
                              class="cursor-pointer text-primary"
                            >
                              <font-awesome-icon
                                icon="external-link-alt"
                                @click="openArticleLink(article.link || article.light_viewer_link)"
                              />
                            </span>
                          </b-col>
                          <b-col cols="auto" class="text-right ml-auto pl-1" style="width: 100px;">
                            <div
                              class="r-25 w-100 text-center"
                              :class="article.used_in_gpt_reply ? 'list-group-item-success' : 'list-group-item-secondary'"
                            >
                              {{ article.used_in_gpt_reply ? 'used in reply' : 'not used' }}
                            </div>
                            <div
                              class="text-center r-25 w-100 mt-1"
                              :class="getArticleScoreColor(activity, article.score)"
                            >
                              <span v-if="article.score !== undefined">
                                {{ (Math.round((article.score || 0) * 10000) / 100).toFixed(2) }}%
                              </span>
                              <span v-else-if="activity.payload.contextType === 'article'">article context</span>
                              <span v-else-if="activity.payload.contextType === 'custom'">manual context</span>
                              <span v-else>-</span>
                            </div>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>

                  <b-col cols="auto" style="width: 10%;" class="grid-col">
                    <small class="text-muted">Response Relevance</small>
                    <div
                      class="r-25 px-1 w-100 mb-1 text-center"
                      :class="getColorBasedOnThreshold(activity.GptRelevanceToQuestionGuarding,
                                                       answerEvaluationThreshold)"
                    >
                      <span v-if="activity.GptRelevanceToQuestionGuarding !== undefined">
                        {{ (Math.round((activity
                          .GptRelevanceToQuestionGuarding || 0) * 10000) / 100)
                          .toFixed(2) }}%
                      </span>
                      <span v-else>-</span>
                    </div>
                    <small class="text-muted">Article Groundedness</small>
                    <div
                      class="r-25 px-1 w-100 mb-1 text-center"
                      :class="getColorBasedOnThreshold(activity.GptGroundednessGuarding,
                                                       groundednessThreshold)"
                    >
                      <span v-if="activity.GptGroundednessGuarding !== undefined">
                        {{ (Math.round((activity.GptGroundednessGuarding || 0) * 10000) / 100)
                          .toFixed(2) }}%
                      </span>
                      <span v-else>-</span>
                    </div>
                    <small class="text-muted">Word Similarity</small>
                    <div
                      class="r-25 px-1 w-100 mb-1 text-center"
                      :class="getColorBasedOnThreshold(activity.JaccardGuarding,
                                                       jaccardGuardingThreshold)"
                    >
                      <span v-if="activity.JaccardGuarding !== undefined">
                        {{ (Math.round((activity.JaccardGuarding || 0) * 10000) / 100)
                          .toFixed(2) }}%
                      </span>
                      <span v-else>-</span>
                    </div>
                    <small class="text-muted">Semantic Similarity</small>
                    <div
                      class="r-25 px-1 w-100 text-center"
                      :class="getColorBasedOnThreshold(activity.EmbeddingGuarding,
                                                       embeddingGuardingThreshold)"
                    >
                      <span v-if="activity.EmbeddingGuarding !== undefined">
                        {{ (Math.round((activity.EmbeddingGuarding || 0) * 10000) / 100)
                          .toFixed(2) }}%
                      </span>
                      <span v-else>-</span>
                    </div>
                    <small class="text-muted">Invalid urls</small>
                    <div
                      class="r-25 px-1 w-100 text-center list-group-item-secondary"
                    >
                      <span v-if="activity.result && activity.result.invalid_urls">
                        {{ activity.result.invalid_urls.length }}
                      </span>
                      <span v-else>-</span>
                    </div>
                  </b-col>
                  <b-col cols="auto" style="width: 28%;" class="grid-col">
                    <b-form-group
                      label="Was one of the predicted articles correct?"
                      :description="getDescription(activity, 'previous_article_feedback')"
                    >
                      <b-button-group size="sm" class="w-100">
                        <b-button
                          :variant="activity.article_feedback === 'yes' || activity.payload.contextType === 'article' ? 'success' : 'outline-success'"
                          :disabled="activity.payload.contextType === 'article'"
                          @click="setAndSaveAiArticleFeedback({ testIndex, activityIndex, value: 'yes' })"
                        >
                          Yes
                        </b-button>
                        <b-button
                          :disabled="activity.payload.contextType === 'article'"
                          :variant="activity.article_feedback === 'no' && activity.payload.contextType !== 'article' ? 'danger' : 'outline-danger'"
                          @click="setAndSaveAiArticleFeedback({ testIndex, activityIndex, value: 'no' })"
                        >
                          No
                        </b-button>
                      </b-button-group>
                    </b-form-group>

                    <b-form-group
                      v-if="activity.reply_sent"
                      :description="getDescription(activity, 'previous_reply_feedback')"
                      label="Generated reply quality"
                      class="mb-2"
                    >
                      <b-button-group size="sm" vertical class="w-100">
                        <b-button
                          :variant="activity.reply_feedback === 'correct' ? 'success' : 'outline-success'"
                          @click="setAndSaveAiReplyFeedback({ testIndex, activityIndex, value: 'correct' })"
                        >
                          Reply was good and accurate
                        </b-button>

                        <b-button
                          :variant="activity.reply_feedback === 'partiallyCorrect' ? 'warning' : 'outline-warning'"
                          @click="setAndSaveAiReplyFeedback({ testIndex, activityIndex, value: 'partiallyCorrect' })"
                        >
                          Reply was acceptable
                        </b-button>
                        <b-button
                          :variant="activity.reply_feedback === 'incorrect' ? 'danger' : 'outline-danger'"
                          @click="setAndSaveAiReplyFeedback({ testIndex, activityIndex, value: 'incorrect' })"
                        >
                          Reply was unacceptable
                        </b-button>
                      </b-button-group>
                    </b-form-group>

                    <b-form-group
                      label="Note"
                      class="mb-0"
                      :description="getDescription(activity, 'previous_notes')"
                    >
                      <b-form-textarea
                        :value="activity.notes"
                        @change="value=>setAndSaveAiNotesFeedback({
                          testIndex, activityIndex, value,
                        })"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </div>

    <AiTestModal
      v-if="testToshow"
      :to-edit-unit-test-idx="modalTestIndex"
      :to-show-unit-test="testToshow"
      @hideModal="resetModalData()"
    />
  </b-container>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import AiTestModal from '@/pages/Health/AiTests/AiTestModal.vue';
import { cloneDeep } from 'lodash';
import AiThresholds from '@/pages/Health/AiTests/AiThresholds.vue';

export default {
  name: 'AITests',
  components: { AiTestModal, AiThresholds },
  data() {
    return {
      totalFinishedTests: 0,
      totalRunningTests: 0,
      testToshow: null,
      modalTestIndex: null,
      showTestModal: false,
      replyMapping: {
        yes: 'Yes',
        no: 'No',
        correct: 'Reply was good and accurate',
        partiallyCorrect: 'Reply was acceptable',
        incorrect: 'Reply was unacceptable',
      },
    };
  },
  computed: {
    ...mapGetters('unitTest', [
      'getAiTests',
      'totalRunningAiTests',
      'getRunningAiTests',
      'getRunningAiTestStatus',
      'totalRunningAiTests',
      'totalFinishedAiTests',
      'totalFailedAiTests',
      'areAiTestsRunning',
      'getCreatingAiTests',
    ]),
    ...mapState('health', ['aiThresholds']),
    articleConfidenceThreshold() {
      return this.aiThresholds.articleConfidenceThreshold || 0;
    },
    articlePresentationThreshold() {
      return this.aiThresholds.articlePresentationThreshold || 0;
    },
    answerEvaluationThreshold() {
      return this.aiThresholds.answerEvaluationThreshold || 0;
    },
    groundednessThreshold() {
      return this.aiThresholds.groundednessThreshold || 0;
    },
    jaccardGuardingThreshold() {
      return this.aiThresholds.jaccardGuardingThreshold || 0;
    },
    embeddingGuardingThreshold() {
      return this.aiThresholds.embeddingGuardingThreshold || 0;
    },
    getAlertVariant() {
      if (this.totalFinishedAiTests === this.totalRunningAiTests && this.totalFailedAiTests === 0) {
        return 'success';
      } if (this.totalFinishedAiTests === this.totalRunningAiTests && this.totalFailedAiTests > 0) {
        return 'danger';
      }
      return 'info';
    },
    allActivities() {
      return this.aiTests.map((t) => t.activities).flat();
    },
    goodReplies() {
      const activities = this.aiTests.map((t) => t.activities).flat();
      return activities.filter((a) => {
        if (!this.isReviewed(a) || a.reply_feedback !== 'correct' || !a.reply_sent) {
          return false;
        }
        return this.passesThresholds(a);
      }).length;
    },
    acceptableReplies() {
      const activities = this.aiTests.map((t) => t.activities).flat();
      return activities.filter((a) => {
        if (!this.isReviewed(a) || a.reply_feedback !== 'partiallyCorrect' || !a.reply_sent) {
          return false;
        }
        return this.passesThresholds(a);
      }).length;
    },
    unacceptableReplies() {
      const activities = this.aiTests.map((t) => t.activities).flat();
      return activities.filter((a) => {
        if (!this.isReviewed(a) || a.reply_feedback !== 'incorrect' || !a.reply_sent) {
          return false;
        }
        return this.passesThresholds(a);
      }).length;
    },
    noReplySent() {
      const activities = this.aiTests.map((t) => t.activities).flat();
      return activities.filter((a) => {
        if (!this.isReviewed(a)) {
          return false;
        }
        if (!a.reply_sent) {
          return true;
        }
        const isSearchEngineContext = a.payload.contextType === 'ranker';
        const highestArticleScore = a.articles
          .reduce((acc, article) => (article.score > acc ? article.score : acc), null);
        return (isSearchEngineContext
          ? (highestArticleScore || 0) * 100 < this.articleConfidenceThreshold : false)
          || (a.GptRelevanceToQuestionGuarding || 0) * 100 < this.answerEvaluationThreshold
          || (a.GptGroundednessGuarding || 0) * 100 < this.groundednessThreshold
          || (a.JaccardGuarding || 0) * 100 < this.jaccardGuardingThreshold
          || (a.EmbeddingGuarding || 0) * 100 < this.embeddingGuardingThreshold;
      });
    },
    missingReview() {
      const activities = this.aiTests.map((t) => t.activities).flat();
      return activities.filter((a) => !this.isReviewed(a)).length;
    },
    correctArticlesIncluded() {
      const activities = this.aiTests.map((t) => t.activities).flat();
      return activities.filter((a) => {
        if (!this.noReplySent.includes(a)) {
          return false;
        }
        if (!this.isReviewed(a) || a.article_feedback !== 'yes') {
          if (a.payload.contextType === 'article') {
            return true;
          }
          return false;
        }
        if (['article', 'custom'].includes(a.payload.contextType)) {
          return true;
        }
        const highestArticleScore = a.articles
          .reduce((acc, article) => (article.score > acc ? article.score : acc), null);
        return highestArticleScore * 100 >= this.articlePresentationThreshold;
      }).length;
    },
    correctArticlesNotIncluded() {
      const activities = this.aiTests.map((t) => t.activities).flat();
      return activities.filter((a) => {
        if (!this.noReplySent.includes(a)) {
          return false;
        }
        if (!this.isReviewed(a) || a.article_feedback !== 'no') {
          return false;
        }
        if (['article', 'custom'].includes(a.payload.contextType)) {
          return true;
        }
        const highestArticleScore = a.articles
          .reduce((acc, article) => (article.score > acc ? article.score : acc), null);
        return highestArticleScore * 100 >= this.articlePresentationThreshold;
      }).length;
    },
    noArticleSent() {
      const activities = this.aiTests.map((t) => t.activities).flat();
      return activities.filter((a) => {
        if (!this.noReplySent.includes(a)) {
          return false;
        }
        if (!this.isReviewed(a)) {
          return false;
        }
        if (['article', 'custom'].includes(a.payload.contextType)) {
          return false;
        }
        const highestArticleScore = a.articles
          .reduce((acc, article) => (article.score > acc ? article.score : acc), null);
        return highestArticleScore * 100 < this.articlePresentationThreshold;
      }).length;
    },
    overallSuccess() {
      const value = (this.goodReplies + this.correctArticlesIncluded) / this.allActivities.length;
      return `${(Math.round((value || 0) * 10000) / 100).toFixed(2)}%`;
    },
    overallWarning() {
      const value = (this.acceptableReplies + this.correctArticlesNotIncluded)
      / this.allActivities.length;
      return `${(Math.round((value || 0) * 10000) / 100).toFixed(2)}%`;
    },
    overallDanger() {
      const value = this.unacceptableReplies / this.allActivities.length;
      return `${(Math.round((value || 0) * 10000) / 100).toFixed(2)}%`;
    },
    aiTests() {
      const tests = this.getAiTests.map((e) => {
        let current = cloneDeep(this.getRunningAiTestStatus?.info?.info[e.key]);
        if (!current) {
          current = {
            testResults: cloneDeep(e.latest_gen_ai_result),
            finished: true,
            failed: e.latest_gen_ai_result?.failed,
            failedMsg: e.latest_gen_ai_result?.msg,
          };
        } else {
          current.testResults = current.gen_ai_result;
          current.failedMsg = current.msgs?.[0];
          const events = [];
          if (current.testResults) {
            (current?.testResults?.events || []).forEach((event, i) => {
              events.push({
                ...event,
                article_feedback: e.latest_gen_ai_result.events[i].article_feedback,
                previous_article_feedback: event.previous_article_feedback,
                reply_feedback: e.latest_gen_ai_result.events[i].reply_feedback,
                previous_reply_feedback: event.previous_reply_feedback,
                notes: e.latest_gen_ai_result.events[i].notes,
                previous_notes: event.previous_notes,
              });
            });
            current.testResults.events = events;
          }
        }

        return {
          ...e,
          hasData: !!current.testResults,
          key: e.key,
          name: e.title,
          failed: current.failed,
          failedMsg: current.failedMsg,
          running: this.getCreatingAiTests[e.key] !== undefined
            || (current ? current.finished !== true : true),
          activities: (current.testResults?.events || []).map((a) => ({
            ...a,
            userQuestion: this.formatQuestion(a),
            articles: a.result?.articles || [],
          })) || [],
          enabled: e.enabled,
        };
      });
      return tests;
    },
    firstActivity() {
      if (this.aiTests.length > 0 && this.aiTests[0].activities.length > 0) {
        return this.aiTests[0].activities[0];
      }
      return {};
    },
    firstRankerActivity() {
      const rankerTests = this.aiTests.filter((test) => {
        if (test.activities.length > 0) {
          return test.activities[0].payload?.contextType === 'ranker';
        }
        return false;
      });
      if (rankerTests.length > 0 && rankerTests[0].activities.length > 0) {
        return rankerTests[0].activities[0];
      }
      return {};
    },
  },
  methods: {
    ...mapMutations('unitTest', ['setAiArticleFeedback', 'setAiReplyFeedback', 'setAiNotesFeedback']),
    ...mapActions('unitTest', ['saveAiFeedback']),
    async runAiTestsProxy() {
      if (await this.$bvModal.msgBoxConfirm(
        'Are you sure you want to run all tests? Running a large number of tests may increase AI resource usage and costs.',
        {
          title: 'Confirm running all tests',
          okVariant: 'warning',
          okTitle: 'Run all',
        })) {
        await this.runAiTests();
      }
    },
    async runAiTests() {
      this.$store.dispatch('unitTest/clearRunningAiTests');
      await this.$store.dispatch('unitTest/runAiTests');
      this.$store.dispatch('unitTest/updateAiTestStatus');
    },
    clearAiTests() {
      if (this.getRunningAiTestStatus) {
        this.$store.dispatch('unitTest/terminateRunningAiTest');
      }
    },
    async deleteClicked(index) {
      if (await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this test?',
        { okTitle: 'Delete', okVariant: 'danger' },
      )) { this.$store.dispatch('unitTest/deleteAiTest', { index }); }
    },
    async runSpecificAiTest(index) {
      const aiTest = this.getAiTests[index];
      await this.$store.dispatch('unitTest/runSpecificAiTest', aiTest);
      this.$store.dispatch('unitTest/updateAiTestStatus');
    },
    resetModalData() {
      this.testToshow = null;
      this.modalTestIndex = null;
      this.showTestModal = false;
    },
    rowClicked(item, index) {
      this.testToshow = item;
      this.modalTestIndex = index;
      this.$nextTick(() => {
        this.showTestModal = true;
      });
    },
    exportTests() {
      const rows = [[
        'Test',
        'Search Query',
        'AI Answer',
        'Predicted Articles (title, was used, score)',
        'AI Answer Evaluation',
        'Groundedness Score',
        'Suggested articles feedback',
        'Generated reply feedback',
        'Note',
      ]];
      this.aiTests.forEach((test) => {
        test.activities.forEach((activity) => {
          const row = [
            test.name,
            activity.payload.query || '-',
            activity.result?.answer || '-',
            activity.articles.map((article) => `${article.title} (${(article.score || 0).toFixed(2) * 100}, ${article.used_in_gpt_reply ? 'used' : 'not used'})`).join(', '),
            '-',
            '-',
            activity.article_feedback || '-',
            activity.reply_feedback || '-',
            activity.notes || '-',
          ];
          rows.push(row);
        });
      });

      const DownloadHeader = 'data:text/csv;charset=utf-8,';
      const csv = `${DownloadHeader}\uFEFF${rows.map((x) => x.join(';')).join('\r\n')}`;
      const encodedUri = encodeURI(csv);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${'BotStudio AI Tests'}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    async setAndSaveAiNotesFeedback({ testIndex, activityIndex, value }) {
      this.setAiNotesFeedback({ testIndex, activityIndex, value });
      await this.saveAiFeedback(testIndex);
    },
    async setAndSaveAiReplyFeedback({ testIndex, activityIndex, value }) {
      this.setAiReplyFeedback({ testIndex, activityIndex, value });
      await this.saveAiFeedback(testIndex);
    },
    async setAndSaveAiArticleFeedback({ testIndex, activityIndex, value }) {
      this.setAiArticleFeedback({ testIndex, activityIndex, value });
      await this.saveAiFeedback(testIndex);
    },
    getDescription(activity, type) {
      if (type === 'previous_notes') {
        return activity?.[type] ? `Previously: ${activity?.[type]}` : '';
      }
      return activity[type] ? `Previously: ${this.replyMapping[activity[type]]}` : '';
    },
    formatQuestion(activity) {
      if (activity.payload.query) {
        return activity.payload.query;
      }
      if (activity.payload.transcript && activity.payload.transcript.length) {
        const customerMsgs = activity.payload.transcript.filter((msg) => msg.startsWith('[Customer]:'));
        if (customerMsgs.length) {
          return customerMsgs[customerMsgs.length - 1].replace('[Customer]:', '');
        }
      }
      return '-';
    },
    openArticleLink(link) {
      window.open(link, '_blank');
    },
    passesThresholds(activity) {
      const isSearchEngineContext = activity.payload.contextType === 'ranker';
      const highestArticleScore = activity.articles
        .reduce((acc, article) => (article.score > acc ? article.score : acc), null);
      return (isSearchEngineContext
        ? highestArticleScore * 100 >= this.articleConfidenceThreshold : true)
          && (activity.GptRelevanceToQuestionGuarding || 0) * 100 >= this.answerEvaluationThreshold
          && (activity.GptGroundednessGuarding || 0) * 100 >= this.groundednessThreshold
          && (activity.JaccardGuarding || 0) * 100 >= this.jaccardGuardingThreshold
          && (activity.EmbeddingGuarding || 0) * 100 >= this.embeddingGuardingThreshold;
    },
    isReviewed(activity) {
      // contextType: article or custom
      if (activity.payload.contextType === 'article' || activity.payload.contextType === 'custom') {
        return activity.reply_feedback !== null || !activity.reply_sent;
      }
      // contextType: ranker
      if (activity.reply_sent) {
        return activity.reply_feedback !== null && activity.article_feedback !== null;
      }
      return activity.article_feedback !== null;
    },
    getReviewState(activity, isState) {
      if ((activity.reply_feedback === null && activity.reply_sent)
      || (activity.article_feedback === null && activity.payload.contextType !== 'article')) {
        return isState ? 'Not reviewed' : 'list-group-item-secondary';
      }
      if (!this.passesThresholds(activity)) {
        return isState ? 'Reviewed (no reply sent)' : 'list-group-item-primary';
      }
      if (activity.reply_feedback === 'incorrect' || activity.article_feedback === 'no') {
        return isState ? 'Reviewed (unacceptable)' : 'list-group-item-danger';
      }
      if (activity.reply_feedback === 'partiallyCorrect') {
        return isState ? 'Reviewed (acceptable)' : 'list-group-item-warning';
      }
      return isState ? 'Reviewed (good)' : 'list-group-item-success';
    },
    getColorBasedOnThreshold(value, threshold) {
      return value * 100 >= threshold ? 'list-group-item-success' : 'list-group-item-warning';
    },
    getArticleScoreColor(activity, score) {
      if (activity.payload.contextType !== 'ranker' || activity.articles.some((a) => a.score > score)) {
        return 'list-group-item-primary';
      }
      return score * 100 >= this.articleConfidenceThreshold ? 'list-group-item-primary' : 'list-group-item-warning';
    },
    isDifferent(activity) {
      if (activity.result?.query) {
        return (activity.result.query).trim().toLowerCase()
      !== activity.userQuestion.trim().toLowerCase();
      }
      return false;
    },
  },
};
</script>
<style scoped>
.header-col{
  border: 1px solid #eee;
  font-weight: bold;
  padding: 0.5rem;
  font-size: 0.82rem;
}
.grid-col{
  border: 1px solid #eee;
  padding: 0.5rem;
  font-size: 0.82rem;
}
.min-height-col{
  min-height: 134px;
}
</style>
