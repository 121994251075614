<template>
  <b-container class="pl-0 pr-4">
    <draggable
      v-if="params.length"
      :value="params"
      role="tablist"
      filter=".ignore-elements"
      :prevent-on-filter="false"
      @input="params => changeOrder(params)"
    >
      <b-row
        v-for="(item, itemIndex) in params"
        :key="itemIndex"
        style="border-left: 1px solid #005F89"
        class="mb-3 pl-1"
        no-gutters
      >
        <b-col
          cols="auto"
          class="pr-1"
          style="margin-top:9px;"
        >
          <font-awesome-icon icon="up-down-left-right" />
        </b-col>

        <b-col class="ignore-elements">
          <b-button
            v-b-tooltip.hover.noninteractive.viewport
            style="position: absolute; right: -40px; top: 0px;"
            title="Delete element"
            @click="removeFormParam(itemIndex)"
          >
            <font-awesome-icon icon="trash-alt" />
          </b-button>
          <b-input-group class="mb-2">
            <b-input-group-prepend :style="`width: ${indentationWidth}px;`">
              <b-input-group-text class="w-100 justify-content-center">
                ID
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              :value="item.id"
              :state="!$v.params.$each[itemIndex].id.$invalid"
              placeholder="Type string here"
              @input="value=>updateFormParam(itemIndex, 'id', value)"
            />
            <b-form-invalid-feedback>
              <template v-if="!$v.params.$each[itemIndex].id.isUnique">
                ID must be unique
              </template>
              <template v-else-if="!$v.params.$each[itemIndex].id.startsWithLetter">
                ID must start with a letter
              </template>
              <template v-else-if="!$v.params.$each[itemIndex].id.isValidId">
                ID can only contains english letters, numbers and _
              </template>
              <template v-else>
                ID is required
              </template>
            </b-form-invalid-feedback>
          </b-input-group>
          <b-input-group class="mb-2">
            <b-input-group-prepend :style="`width: ${indentationWidth}px`">
              <b-input-group-text class="w-100 justify-content-center">
                Type
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-select
              :value="item.type"
              :options="inputTypes"
              disabled
            />
          </b-input-group>

          <b-input-group class="mb-2">
            <b-input-group-prepend :style="`width: ${indentationWidth}px;`">
              <b-input-group-text class="w-100 justify-content-center">
                Label
              </b-input-group-text>
            </b-input-group-prepend>
            <botscript-validation
              :value="item.text"
              :validations="['empty']"
              :wrapper-style="`width: calc(100% - ${indentationWidth}px) !important;`"
              input-style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
              @onChange="value=>updateFormParam(itemIndex, 'text', value)"
            />
          </b-input-group>

          <div
            v-if="item.type === 'text'"
          >
            <b-input-group class="mb-2">
              <b-input-group-prepend :style="`width: ${indentationWidth}px;`">
                <b-input-group-text class="w-100 justify-content-center">
                  Validation
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-select
                :value="item.frontend_validation"
                :options="validationTypes"
                @input="value=>updateFormParam(itemIndex, 'frontend_validation', value)"
              />
            </b-input-group>
          </div>

          <div
            v-if="item.type === 'select'"
            class="mt-2 pl-1"
            :style="`margin-left: ${indentationWidth}px; border-left: 1px solid #5BC0DE` "
          >
            <div
              v-for="(option, optionIndex) in item.options"
              :key="optionIndex"
              class="d-block"
            >
              <b-input-group class="mb-2">
                <b-input-group-prepend :style="`width: ${indentationWidth}px`">
                  <b-input-group-text class="w-100 justify-content-center">
                    ID
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  :value="option.id"
                  :state="!$v.params.$each[itemIndex]
                    .options.$each[optionIndex].$invalid"
                  placeholder="Type string here"
                  @input="value=>updateFormOption(itemIndex, optionIndex, 'id', value)"
                />
                <b-form-invalid-feedback>
                  <template
                    v-if="!$v.params.$each[itemIndex].options.$each[optionIndex].id.isUnique"
                  >
                    ID must be unique
                  </template>
                  <template
                    v-else-if="!$v.params.$each[itemIndex]
                      .options.$each[optionIndex].id.startsWithLetter"
                  >
                    ID must start with a letter
                  </template>
                  <template
                    v-else-if="!$v.params.$each[itemIndex].options.$each[optionIndex]
                      .id.isValidId"
                  >
                    ID can only contain english letters, numbers and _
                  </template>
                  <template v-else>
                    ID is required
                  </template>
                </b-form-invalid-feedback>
                <b-button
                  v-if="optionIndex !== 0"
                  v-b-tooltip.hover.noninteractive.viewport
                  title="Delete element"
                  style="position: absolute; right: -40px; top: 0px;"
                  @click="removeFormOption(itemIndex, optionIndex)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </b-button>
              </b-input-group>

              <b-input-group class="my-2">
                <b-input-group-prepend :style="`width: ${indentationWidth}px`">
                  <b-input-group-text class="w-100 justify-content-center">
                    Label
                  </b-input-group-text>
                </b-input-group-prepend>
                <botscript-validation
                  :value="option.text"
                  :validations="['empty']"
                  :wrapper-style="`width: calc(100% - ${indentationWidth}px) !important;`"
                  input-style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;"
                  @onChange="value=>updateFormOption(itemIndex, optionIndex, 'text', value)"
                />
              </b-input-group>
            </div>
            <div>
              <small
                v-if="!$v.params.$each[itemIndex].options.isRequiredLength"
                class="text-danger"
                small
              >
                The number of options must be between 1 and 16
              </small>
            </div>
            <b-button
              variant="primary"
              class="px-3"
              @click="addFormOption(itemIndex)"
            >
              <font-awesome-icon icon="plus" />
              option
            </b-button>
          </div>
        </b-col>
      </b-row>
    </draggable>
    <div>
      <small
        v-if="!$v.params.isRequiredLength"
        class="text-danger"
      >
        The number of options must be between 1 and 16
      </small>
    </div>
    <b-btn-group>
      <b-button
        v-for="(type, index) in inputTypes"
        :key="index"
        variant="primary"
        class="px-3"
        @click="addFormParam(type)"
      >
        <font-awesome-icon icon="plus" />
        {{ type }}
      </b-button>
    </b-btn-group>
  </b-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import Draggable from 'vuedraggable';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import BotscriptValidation from '@/components/BotscriptValidation.vue';

export default {
  name: 'ChatForm',
  components: {
    Draggable,
    BotscriptValidation,
  },
  mixins: [validationMixin],
  props: {
    nodeId: {
      type: String,
      required: true,
    },
    activityId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputTypes: ['text', 'select'],
      validationTypes: [
        { value: null, text: 'None' },
        { value: 'email', text: 'Email' },
        { value: 'telDK', text: 'Phone Number (dk)' },
      ],
      indentationWidth: 80,
      param: 'fields',
    };
  },
  computed: {
    ...applyThisArgs(mapGetters('botManipulation/activeBot', {
      activityChatActionParams: 'activityParams',
    }), 'nodeId', 'activityId'),
    params: {
      get() {
        return this.activityChatActionParams[this.paramIndex].value;
      },
      set(v) {
        this.setChatFormParams({
          index: this.paramIndex,
          params: v,
          activityId: this.activityId,
        });
      },
    },
    paramIndex() {
      return this.activityChatActionParams.findIndex((e) => e.key === this.param);
    },
  },
  methods: {
    ...addThisArgs(mapMutations('botManipulation/activeBot', [
      'setChatFormParams',
    ]), { nodeId: 'nodeId', param: 'paramIndex' }),
    changeOrder(params) {
      this.params = params;
    },
    addFormParam(type) {
      if (type === 'text') {
        this.params = this.params.concat({
          id: '', type, text: '', frontend_validation: null,
        });
      } else {
        this.params = this.params.concat({
          id: '', type, text: '', options: [{ id: '', text: '' }],
        });
      }
    },
    removeFormParam(index) {
      this.params = this.params.filter((e) => this.params.indexOf(e) !== index);
    },
    updateFormParam(index, type, value) {
      const copy = cloneDeep(this.params);
      copy[index][type] = value;
      this.params = copy;
    },
    addFormOption(index) {
      const copy = cloneDeep(this.params);
      copy[index].options.push({ id: '', text: '' });
      this.params = copy;
    },
    removeFormOption(itemIndex, optionIndex) {
      const copy = cloneDeep(this.params);
      copy[itemIndex].options = copy[itemIndex].options.filter(
        (e) => copy[itemIndex].options.indexOf(e) !== optionIndex);
      if (copy[itemIndex].options.length < 1) {
        copy[itemIndex].options.push({ id: '', text: '' });
      }
      this.params = copy;
    },
    updateFormOption(itemIndex, optionIndex, type, value) {
      const copy = cloneDeep(this.params);
      copy[itemIndex].options[optionIndex][type] = value;
      this.params = copy;
    },
  },
  validations() {
    return {
      params: {
        isRequiredLength(params) {
          return params.length >= 1 && params.length <= 16;
        },
        $each: {
          id: {
            isUnique(value) {
              return this.params.filter((x) => x.id === value).length < 2;
            },
            startsWithLetter(value) {
              return !!value.match(/^[A-Za-z]/);
            },
            isValidID(value) {
              return !!value.match(/^[A-Za-z][A-Za-z0-9_]{0,127}$/);
            },
            required,
          },
          options: {
            isRequiredLength(options) {
              if (options !== undefined) {
                return options.length >= 1 && options.length <= 16;
              }
              return true;
            },
            $each: {
              id: {
                isUnique(value) {
                  let allOptions = this.params.filter((e) => e.options);
                  allOptions = allOptions.map((e) => e.options.map((x) => x.id)).flat(1);
                  return allOptions.filter((e) => e === value).length < 2;
                },
                startsWithLetter(value) {
                  return !!value.match(/^[A-Za-z]/);
                },
                isValidID(value) {
                  return !!value.match(/^[A-Za-z][A-Za-z0-9_]{0,127}$/);
                },
                required,
              },
            },
          },
        },
      },
    };
  },
};
</script>
<style scoped>
::v-deep .form-control{
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
::v-deep .input-group-text, ::v-deep .input-group-append,
::v-deep .custom-select, ::v-deep .form-control{
  height: 37px !important;
}
</style>
