<template>
  <main>
    <b-card
      no-body
      class="r-75 d-flex flex-column px-0"
      :class="unsavedChanges ? 'border' : ''"
    >
      <div
        v-if="chosenSection"
        class="d-flex flex-row mb-3 top-buttons"
      >
        <b-button
          v-for="(item, index) in filteredSections"
          :key="index"
          v-b-tooltip.hover.bottom
          :variant="isActive(item.name) ? 'secondary' : 'light'"
          :title="item.name"
          class="flex-fill p-0 "
          @click="chooseSection(item.name)"
        >
          <b-img
            :src="require(`@/assets/configIcons/${item.icon}`)"
            fluid
            class="mx-auto my-0"
            style="max-width:40px"
            alt="Responsive image"
          />
        </b-button>
      </div>
      <div
        class="mx-3 mt-0"
      >
        <b-row
          v-if="!chosenSection"
          class="mb-2 pr-2"
        >
          <b-col
            v-for="(item, index) in filteredSections"
            :key="index"
            :cols="windowWidth <= 1480 ? '4' : '3'"
            class="mt-2 mb-0 pl-2 pr-0"
          >
            <b-card
              style="border-radius:2px; cursor:pointer"
              no-body
              class="text-center shadow-sm cart"
              @click="chooseSection(item.name)"
            >
              <span
                v-b-tooltip.top.hover.noninteractive.viewport
                :title="tooltipsOn ? item.tooltipText : ''"
              >
                <b-img
                  :src="require(`@/assets/configIcons/${item.icon}`)"
                  fluid
                  class="mx-auto my-2"
                  style="max-width:90px"
                  alt="Responsive image"
                />

                <h4
                  class="mb-2"
                  style="font-size: 1.4em;"
                >
                  {{ item.name }}
                </h4></span>
            </b-card>
          </b-col>
        </b-row>
        <div
          v-if="chosenSection"
          key="2"
          class="w-100 mb-3"
        >
          <basic-config
            v-if="chosenSection === 'general'"
            key="1"
          />
          <subflow-variables
            v-if="!isMainBot && chosenSection === 'subflow-variables'"
            key="3"
          />

          <edit-authentication
            v-if="isMainBot && chosenSection === 'authentication'"
            key="7"
            @unsavedChanges="(val)=>updateUnsavedChanges(val)"
          />
          <edit-initialization
            v-if="isMainBot && chosenSection === 'initialization'"
            key="8"
          />
          <bot-config-final-activities
            v-if="isMainBot && chosenSection === 'final-activities'"
            key="9"
          />
          <InactivityPage
            v-if="isMainBot && chosenSection === 'inactivity'"
            key="10"
          />
          <EditSmartNode
            v-if="isMainBot && chosenSection === 'smart-nodes'"
            key="11"
            @unsavedChanges="(val)=>updateUnsavedChanges(val)"
          />
          <voice
            v-if="isMainBot && voicebotPlatformSelected && chosenSection === 'voice'"
            key="14"
          />
          <fallback-behavior-config
            v-if="isMainBot && chosenSection === 'fallback-behavior'"
            key="15"
          />
          <anonymization
            v-if="isMainBot && chosenSection === 'anonymization' && anonymizationConfigId"
            :id="anonymizationConfigId"
            key="16"
            @unsavedChanges="(val)=>updateUnsavedChanges(val)"
          >
            <template slot="description">
              <p>
                Here you can specify when and how user data should be anonymized. Note that any
                entities registered here should also be added to the
                <router-link :to="{ name: 'entities' }">
                  entities page
                </router-link>
                with a variable name equal to the replacement text. This
                ensures that the bot has a better understanding of the anonymized data that it is
                trained on.
              </p>
              <p class="mt-2">
                An anoymization job is run each night if anonymization is enabled. You can use
                delayed anonymization to skip the newest chats for a set period. With immediate
                anonymization, any chat will be anonymized the moment it is ended.
              </p>
            </template>
          </anonymization>
          <SignatureStripping
            v-if="isMainBot && chosenSection === 'signature-stripping'"
            key="17"
          />
          <GenerativeAI
            v-if="isMainBot && chosenSection === 'generative-ai'"
            key="18"
          />
        </div>
      </div>
    </b-card>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Anonymization from 'supwiz/components/anonymization/Anonymization.vue';
import axios from 'axios';
import BasicConfig from '@/pages/BotConfig/BasicConfig.vue';
import InactivityPage from '@/pages/BotConfig/InactivityPage.vue';
import SubflowVariables from '@/pages/BotConfig/SubflowVariables.vue';
import EditAuthentication from '@/pages/BotConfig/EditAuthentication.vue';
import EditSmartNode from '@/pages/BotConfig/EditSmartNode.vue';
import EditInitialization from '@/pages/BotConfig/EditInitialization.vue';
import Voice from '@/pages/BotConfig/Voice.vue';
import BotConfigFinalActivities from '@/pages/BotConfig/FinalActivities/BotConfigFinalActivities.vue';
import SignatureStripping from '@/pages/BotConfig/SignatureStripping.vue';
import FallbackBehaviorConfig from '@/pages/BotConfig/FallbackBehaviorConfig.vue';
import endpoints from '@/js/urls';
import GenerativeAI from '@/pages/BotConfig/GenerativeAI.vue';
import { isGenerativeAiEnabled } from '@/js/featureFlags';

export default {
  name: 'BotconfigPage',
  components: {
    EditAuthentication,
    EditSmartNode,
    InactivityPage,
    BasicConfig,
    SubflowVariables,
    Voice,
    BotConfigFinalActivities,
    EditInitialization,
    FallbackBehaviorConfig,
    Anonymization,
    SignatureStripping,
    GenerativeAI,
  },
  beforeRouteEnter(to, from, next) {
    const hash = to.hash.toLowerCase().replace('#', '');
    if (hash) {
      next((vm) => {
        vm.chooseSection(hash);
      });
    }
    next();
  },
  beforeRouteUpdate(to, from, next) {
    const hash = to.hash.toLowerCase().replace('#', '');
    if (hash) {
      this.chosenSection = hash;
    } else {
      this.chosenSection = null;
    }
    next();
  },
  data() {
    return {
      unsavedChanges: false,
      configSections: [
        { name: 'General', icon: 'cog.svg', tooltipText: 'Configure general bot settings' },
        {
          name: 'Subflow Variables', isMainBot: false, icon: 'subflowvariables.svg', tooltipText: 'Set variables for subflows only',
        },
        {
          name: 'Authentication', isMainBot: true, icon: 'authentication.svg', tooltipText: 'Configure user authentication',
        },
        {
          name: 'Initialization', isMainBot: true, icon: 'initialization.svg', tooltipText: 'Initialize variables before conversation starts',
        },
        {
          name: 'Final Activities', isMainBot: true, icon: 'finalactivities.svg', tooltipText: 'Run activities after conversation has ended',
        },
        {
          name: 'Inactivity', isMainBot: true, icon: 'inactivity.svg', tooltipText: 'Define inactivity messages',
        },
        {
          name: 'Smart Nodes', isMainBot: true, icon: 'brain.svg', tooltipText: 'Set default behavior of smart nodes',
        },
        {
          name: 'Voice', isMainBot: 'voice', icon: 'voice.svg', tooltipText: 'Set voice-specific bot settings',
        },
        {
          name: 'Fallback Behavior', isMainBot: true, icon: 'fallbackbehavior.svg', tooltipText: 'Set actions invoked before going to fallback node',
        },
        {
          name: 'Anonymization', isMainBot: true, icon: 'anonymization.svg', tooltipText: 'Anonymize user data',
        },
        {
          name: 'Signature Stripping', isMainBot: 'ticket', icon: 'signature.svg', tooltipText: 'Strip signature from user messages',
        },
        {
          name: 'Generative AI', isMainBot: true, icon: 'aiengine.svg', tooltipText: 'Configure Generative AI actions',
        },
      ],
      chosenSection: null,
      windowWidth: window.innerWidth,
      anonymizationConfigId: null,
    };
  },
  computed: {
    ...mapGetters('userSettings', ['tooltipsOn']),
    ...mapGetters('botManipulation', ['getBotId']),
    ...mapGetters('botManipulation/activeBot/config', [
      'isMainBot',
      'getPlatforms',
    ]),
    ...mapGetters('userSettings', ['isVoicebotPlatform']),
    voicebotPlatformSelected() {
      return this.isVoicebotPlatform(this.getPlatforms);
    },
    filteredSections() {
      const filtered = [];
      this.configSections.filter((element) => element.name !== 'Generative AI' || isGenerativeAiEnabled())
        .forEach((element) => {
          if (element.isMainBot === undefined) {
            filtered.push(element);
          }
          if (element.isMainBot === this.isMainBot) {
            filtered.push(element);
          }
          if (element.isMainBot === 'voice' && this.isMainBot && this.voicebotPlatformSelected) {
            filtered.push(element);
          }
          if (element.isMainBot === 'ticket' && this.isMainBot) {
            if (this.getPlatforms.includes('zendesk-support')
                || this.getPlatforms.includes('jsonwebhook')
                || this.getPlatforms.includes('topdesk-incident')
                || this.getPlatforms.includes('puzzel-ticket')
                || this.getPlatforms.includes('freshdesk-ticket')
            ) {
              filtered.push(element);
            }
          }
        });
      return filtered;
    },
  },
  async mounted() {
    if (this.isMainBot) {
      await this.setAnonymizationConfigId();
    }
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    ...mapActions('sidebar', ['showWarning']),
    chooseSection(name) {
      const tabHash = `#${name.replace(/ /g, '-').toLowerCase()}`;
      if (this.$route.hash.toLowerCase() !== tabHash) {
        this.$router.replace({ hash: tabHash });
      }
      this.chosenSection = name.replace(/ /g, '-').toLowerCase();
      if (document.activeElement) {
        document.activeElement.blur();
      }
    },
    isActive(name) {
      const h = this.$route.hash;
      if (h.toLowerCase().includes(name.replace(/ /g, '-').toLowerCase())) {
        return true;
      }
      return false;
    },
    updateUnsavedChanges(val) {
      this.unsavedChanges = val;
    },
    async setAnonymizationConfigId() {
      try {
        const { data } = await axios.get(`${endpoints.botAnonymizationConfigId}`, {
          headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
          params: { bot_id: this.getBotId },
        });
        this.anonymizationConfigId = data.anonymizationConfigId;
      } catch (e) {
        this.showWarning({
          title: 'Failed to fetch anonymization',
          text: e.message,
          variant: 'danger',
        });
      }
    },
  },
};

</script>
<style scoped>
.cart{
  transition: transform 50ms;
}
.cart:hover {
  transform: translateY(-5px);
}
.top-buttons{
  margin: 1px 1px 0px 1px;
}
.top-buttons > .btn{
  border-radius: 0%;
}
.top-buttons > button:first-child{
  border-top-left-radius: .75rem;
}
.top-buttons > button:last-child{
  border-top-right-radius: .75rem;
}
.border{
  border:3px solid #E99002 !important;
}
</style>
