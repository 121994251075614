<template>
  <div class="p-3">
    <b-card
      title="Filtering"
      body-class="p-0"
    >
      <!-- Some basic and common filtering options. -->
      <b-form-group>
        <b-form-checkbox-group
          v-model="selectedFilterOptionsProxy"
          :options="filterOptions"
          stacked
        />
      </b-form-group>
    </b-card>
    <b-card
      v-if="showPhraseIntegrationComponents && phraseIntegrationConfig.isEnabled"
      body-class="p-0"
      class="mb-3"
    >
      <b-card-title>
        <em>Phrase</em> integration settings
      </b-card-title>
      <b-form>
        <b-form-group>
          <template #label>
            <em>Phrase</em> locale id
          </template>
          <b-form-input
            v-model="variantPhraseLocaleId"
          />
        </b-form-group>
        <b-button
          variant="success"
          @click="fetchSelectedVariantLocaleFromPhraseProxy"
        >
          <b-spinner
            v-if="isFetching"
            small
            style="margin-bottom:1px"
          />
          Download texts from <em>Phrase</em>
        </b-button>
      </b-form>
    </b-card>
    <b-row>
      <b-col
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <b-input-group>
            <b-form-input
              v-model="filter"
              class="filter-input"
              placeholder="Type to Search"
            />

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                variant="primary"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>

            <b-input-group-append>
              <b-button
                variant="primary"
                @click="toggleAllDetails"
              >
                Toggle all details
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="save-row">
      <b-col class="mb-1">
        <b-button
          block
          :disabled="isVariantsWorking || isSaveDisabled"
          variant="primary"
          @click="saveVariantPhrasesProxy"
        >
          <b-spinner
            v-if="isVariantsWorking"
            small
          />
          Save All
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      />
    </b-row>
    <b-table
      ref="responsesTable"
      :items="items"
      :fields="fields"
      :filter="filter"
      :tbody-tr-class="locationClass"
      :per-page="perPage"
      :current-page="currentPage"
      show-empty
      responsive
      hover
      details-td-class="bg-white cursor-auto table-details"
      class="cursor-pointer"
      @row-clicked="toggleDetails"
      @filtered="updatePagination"
    >
      <template #cell(location)="row">
        <template v-if="row.item.prependPath[0] === 'config'">
          <span class="font-weight-bold">Element: </span>
          {{ row.item.prependPath.join(' > ') }}
        </template>
        <template v-else-if="row.item.prependPath[0] === 'nodes'">
          <span class="font-weight-bold">Response/text for: </span>
          <b-link :to="editNodeLink(row.item.prependPath[1])">
            {{ nodeById(row.item.prependPath[1]).name }}
          </b-link>
        </template>
        <template v-else-if="row.item.prependPath[0] === 'phrases'">
          <span class="font-weight-bold">Element: </span>
          {{ getFormattedPhrasePath(row.item.prependPath) }}
        </template>
        <template v-else>
          <span class="font-weight-bold">Element: </span>
          {{ row.item.prependPath.join(' > ') }}
        </template>
      </template>
      <template #cell(actions)="{ detailsShowing }">
        <h5 class="mr-1 mb-0">
          <font-awesome-icon :icon="detailsShowing ? 'angle-up' : 'angle-down'" />
        </h5>
      </template>

      <template #head(unchanged)="data">
        <span
          v-b-tooltip.hover
          title="Shows whether the response was saved after the master bot's text was last changed."
        >
          {{ data.label }}
        </span>
      </template>
      <template #cell(unchanged)="row">
        <font-awesome-icon
          v-if="row.item.unchanged"
          v-b-tooltip.hover
          title="Master unchanged"
          icon="check-circle"
          class="text-success"
        />
        <font-awesome-icon
          v-else-if="row.item.unchanged === false"
          v-b-tooltip.hover
          title="Master updated"
          icon="exclamation-circle"
          class="text-warning"
        />
      </template>

      <template #head(approved)="data">
        <span
          v-b-tooltip.hover
          title="Shows whether the response has been approved using the approve-switch."
        >
          {{ data.label }}
        </span>
      </template>
      <template #cell(approved)="row">
        <font-awesome-icon
          v-if="isApproved(row.item.location)"
          v-b-tooltip.hover
          title="Approved"
          icon="check-circle"
          class="text-success"
        />
        <font-awesome-icon
          v-else
          v-b-tooltip.hover
          title="Not approved"
          icon="exclamation-circle"
          class="text-warning"
        />
      </template>

      <template #row-details="row">
        <div>
          <span class="font-weight-bold">Original text:</span>
          <div v-for="(item, index) in getTextBlocks(row.item.elem.text)" :key="index">
            <phrase-building-block
              v-if="item.isPhrase"
              :id="phrase2Id(item.value)"
              hide-drag
              hide-actions
            />
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-else class="border r-25 p-2" style="background-color: #EEEEEE; min-height:37px;" v-html="filteredText(item.value)" />
          </div>
        </div>
        <div>
          <span class="font-weight-bold">Variant text:</span>
          <draggable
            :list="getTextBlocks(row.item.phraseValue)"
            :options="draggableOptions"
            @change="v=>updateOrder(v, row.item)"
          >
            <div v-for="(item, index) in getTextBlocks(row.item.phraseValue)" :key="index">
              <b-row v-if="item.isPhrase">
                <b-col cols="auto" class="pr-1 draggable-handle">
                  <b-button size="sm" variant="text" disabled class="mt-3 cursor-move">
                    <font-awesome-icon icon="arrows-up-down-left-right" />
                  </b-button>
                </b-col>
                <b-col>
                  <phrase-building-block
                    :id="phrase2Id(item.value)"
                    edit-disabled
                    :value="getVariantPhraseValue(item)"
                    @remove="v=>removePhraseFromVariantResponse(index, row.item)"
                  />
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="auto" class="pr-1 draggable-handle">
                  <b-button size="sm" variant="text" disabled class="mt-3 cursor-move">
                    <font-awesome-icon icon="arrows-up-down-left-right" />
                  </b-button>
                </b-col>
                <b-col>
                  <quill-wrapper
                    v-if="wysiwygEnabled"
                    ref="quillWrapper"
                    :text="item.value"
                    @blur="e => setVariantText(row, e)"
                    @update:text="v=>touch(row.item, v)"
                  />
                  <b-form-textarea
                    v-else
                    rows="2"
                    :value="item.value"
                    @blur="e => setVariantText(row, e)"
                    @input="v=>touch(row.item, v)"
                  />
                  <small
                    v-if="!item.value && !isDirty[row.item.location]"
                    class="text-warning"
                  >
                    Variant response has no text, original text will be used.
                  </small>
                </b-col>
              </b-row>
            </div>
          </draggable>
          <phrase-selector
            v-if="!excludePhraseSelector.includes(row.item.prependPath[0])"
            hide-add
            :response-preview="getPreview(row.item)"
            @add="v=>addPhraseToVariantResponse(v, row.item)"
          />
          <approve-button
            :value="getPhraseApproved(row.item.location)"
            type="message"
            class="mb-1"
            @input="v => touchApproveButton(row.item, v)"
          />
          <b-button
            class="mt-1 mr-1"
            variant="primary"
            :disabled="isVariantsWorking || !isDirty[row.item.location]"
            size="sm"
            @click="saveVariantPhrasesProxy({ phraseKey: row.item.location })"
          >
            Save
            <b-spinner
              v-if="isVariantsWorking"
              small
            />
          </b-button>
          <b-button
            class="mt-1"
            variant="secondary"
            :disabled="isVariantsWorking || !isDirty[row.item.location]"
            size="sm"
            @click="() => discardChanges(row.item.location, row.item)"
          >
            Discard changes
          </b-button>
          <b-button
            v-if="showTranslationComponents"
            v-b-tooltip.hover.noninteractive.viewport
            class="mt-1 ml-3"
            :disabled="isTranslatingSingle || !hasSelectedLanguage "
            size="sm"
            :title="hasSelectedLanguage ? '' : 'Variant routing language not selected'"
            variant="primary"
            @click="() => translateVariantPhrase(row.item)"
          >
            <b-spinner
              v-if="isTranslatingSingle"
              small
            />
            Translate
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      />
    </b-row>
    <b-modal
      id="phrase-modal"
      ok-title="Import"
      scrollable
      @ok="fetchSelectedVariantLocaleFromPhraseCommitProxy"
    >
      <template #modal-title>
        Are you sure you want to import from <em>Phrase</em>?
      </template>
      <div v-if="response">
        <p>{{ response.data.keys_new.length }} keys will be created.</p>
        <b-list-group flush>
          <b-list-group-item
            v-for="(key, index) in response.data.keys_new"
            :key="index"
            class="py-1 px-0 break-text"
          >
            {{ key }}
          </b-list-group-item>
        </b-list-group>
        <p class="mt-2">
          {{ response.data.keys_upd.length }} keys will be updated.
        </p>
        <b-list-group>
          <b-list-group-item
            v-for="(key, index) in response.data.keys_upd"
            :key="index"
            class="py-1 px-0 break-text"
          >
            {{ key }}
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { cloneDeep } from 'lodash';
import Draggable from 'vuedraggable';
import {
  formatTextWithLinebreaks,
  UUID_REGEX, phrase2Id, mapPhrases,
} from '@/js/utils';
import { isPhraseIntegrationEnabled } from '@/js/featureFlags';
import ApproveButton from '@/components/ApproveButton.vue';
import { PHRASE_REGEX } from '@/js/constants';
import PhraseSelector from '@/pages/Phrases/PhraseSelector.vue';
import PhraseBuildingBlock from '@/pages/Phrases/PhraseBuildingBlock.vue';
import QuillWrapper from '@/components/QuillWrapper.vue';

export default {
  name: 'VariantPhrases',
  components: {
    ApproveButton, QuillWrapper, PhraseSelector, PhraseBuildingBlock, Draggable,
  },
  data() {
    return {
      isFetching: false,
      phraseModalReady: false,
      response: null,
      filterOptions: [
        { value: 'show_only_empty_variant', text: 'Show only responses with empty variant text.' },
        { value: 'hide_empty_master', text: 'Hide responses with empty master text.' },
        { value: 'hide_unchanged', text: 'Only show responses with updated master text.' },
        { value: 'show_only_unapproved', text: 'Only show responses that have not been approved yet.' },
      ],
      fields: [
        'location',
        'unchanged',
        'approved',
        {
          key: 'actions',
          label: '',
          thClass: 'text-center',
        },
      ],
      filter: '',
      showAllDetails: false,
      rowDetails: {},
      perPage: 20,
      currentPage: 1,
      rows: 0,
      excludePhraseSelector: ['config', 'errorNode', 'fallbackNode', 'elaborationNode', 'phrases'],
      isDirty: {},
      draggableOptions: {
        handle: '.draggable-handle',
      },
    };
  },
  computed: {
    ...mapGetters('botManipulation/activeBot/config', [
      'getRoutingLanguage', 'getPlatforms', 'wysiwygEnabled']),
    ...mapGetters('variants', [
      'isTranslatingSingle',
      'hasSelectedLanguage',
      'selectedLanguage',
      'isVariantsRefreshing',
      'isVariantsSaving',
      'selectedVariantPhrases',
      'masterPhrases',
      'selectedFilterOptions',
      'phraseIntegrationConfig',
      'selectedVariantPhraseLocaleId',
    ]),
    ...mapGetters('botManipulation/activeBot', ['phrasesDict', 'phrases', 'nodeById']),
    ...mapGetters('userSettings', ['showTranslationFeatures']),
    isVariantsWorking() {
      return this.isVariantsRefreshing || this.isVariantsSaving;
    },
    showPhraseIntegrationComponents() {
      return isPhraseIntegrationEnabled;
    },
    showTranslationComponents() {
      return this.showTranslationFeatures && this.hasSelectedLanguage
        && this.selectedLanguage !== this.getRoutingLanguage;
    },
    selectedFilterOptionsProxy: {
      get() {
        return this.selectedFilterOptions;
      },
      set(newOptions) {
        this.setSelectedFilterOptions(newOptions);
      },
    },
    variantPhraseLocaleId: {
      get() {
        return this.selectedVariantPhraseLocaleId;
      },
      set(newValue) {
        this.setSelectedVariantPhraseLocaleId(newValue);
      },
    },
    items() {
      return Object.entries(this.masterPhrases).filter(([k]) => this.shouldShowPhrase(k))
        .map(([k, v]) => ({
          location: k,
          unchanged: this.masterUnchanged(k),
          elem: v.elem,
          prependPath: v.prependPath,
          _showDetails: this.rowDetails[k] !== undefined
            ? this.rowDetails[k] : this.showAllDetails,
          phraseValue: this.getPhraseValue(k),
        }));
    },
    isSaveDisabled() {
      return !Object.values(this.isDirty).some((x) => x === true);
    },
  },
  watch: {
    isSaveDisabled(newValue) {
      this.$emit('dirty', !newValue);
    },
    filter() {
      this.showAllDetails = false;
      this.rowDetails = {};
    },
  },
  beforeDestroy() {
    // Make sure we signal that the component is no longer dirty
    this.$emit('dirty', false);
  },
  mounted() {
    this.rows = this.items.length;
  },
  methods: {
    ...mapActions('variants', [
      'translateVariantPhraseKey',
      'saveVariantPhrases',
      'fetchSelectedVariantLocaleFromPhrase',
      'fetchSelectedVariantLocaleFromPhraseCommit',
    ]),
    ...mapMutations('variants', [
      'setSingleVariantPhrase',
      'setSingleVariantApproved',
      'setSelectedFilterOptions',
      'setSelectedVariantPhraseLocaleId',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    phrase2Id,
    saveVariantPhrasesProxy({ phraseKey }) {
      if (this.wysiwygEnabled) {
        this.$refs.quillWrapper[0].$refs.editor.quill.blur();
      }
      this.$nextTick(() => {
        this.saveVariantPhrases({ phraseKey });
        if (phraseKey) {
          this.isDirty[phraseKey] = false;
        } else {
          this.isDirty = {};
        }
      });
    },
    setVariantText(row, event) {
      const value = this.wysiwygEnabled ? event.root.innerHTML : event.srcElement.value;
      let cleaned = value;
      const matches = value ? value.match(PHRASE_REGEX) : null;
      if (matches) {
        matches.forEach((e) => {
          if (this.phrasesDict[this.phrase2Id(e)]) {
            this.addPhraseToVariantResponse({ id: this.phrase2Id(e) }, row.item);
          }
        });
        cleaned = value.split(PHRASE_REGEX).filter((e) => e.length > 0).join('');
      }
      const blocksCopy = cloneDeep(this.getTextBlocks(row.item.phraseValue));
      let finalText = '';
      blocksCopy.forEach((block) => {
        finalText += block.isPhrase ? block.value : (cleaned || '');
      });
      this.variantPhraseApproved(row.item.location, row.item, false);
      this.variantPhraseChange(row.item.location, row.item, finalText);
    },
    updatePagination(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    isApproved(key) {
      return this.selectedVariantPhrases[key]?.elem?.approved;
    },
    masterUnchanged(key) {
      const elem = this.selectedVariantPhrases[key]?.elem;
      if (elem === undefined || elem.masterHash === undefined) {
        return undefined;
      }
      return this.masterPhrases[key].elem.hash === elem.masterHash;
    },
    shouldShowPhrase(phraseKey) {
      if (!(phraseKey in this.masterPhrases)) {
        return false;
      }
      if (this.selectedFilterOptions.includes('hide_empty_master')
          && !this.masterPhrases[phraseKey].elem.text) {
        return false;
      }
      if (this.selectedFilterOptions.includes('show_only_empty_variant')
          && (phraseKey in this.selectedVariantPhrases
          && !!this.selectedVariantPhrases[phraseKey].elem.masterHash)) {
        return false;
      }
      if (this.selectedFilterOptions.includes('hide_unchanged')
          && this.masterUnchanged(phraseKey)) {
        return false;
      }
      if (this.selectedFilterOptions.includes('show_only_unapproved')
          && phraseKey in this.selectedVariantPhrases
          && this.selectedVariantPhrases[phraseKey].elem.approved) {
        return false;
      }
      return true;
    },
    locationClass(item) {
      if (item === null) {
        return '';
      }
      return this.isDirty[item.location] ? 'dirty-row' : '';
    },
    variantPhraseApproved(phraseKey, phrase, approved) {
      this.setSingleVariantApproved({ phraseKey, path: phrase.prependPath, approved });
    },
    variantPhraseChange(phraseKey, phrase, text) {
      this.setSingleVariantPhrase({ phraseKey, path: phrase.prependPath, text });
    },
    async fetchSelectedVariantLocaleFromPhraseProxy() {
      this.isFetching = true;
      try {
        this.response = await this.fetchSelectedVariantLocaleFromPhrase();
        setTimeout(() => {
          this.isFetching = false;
          this.$bvModal.show('phrase-modal');
        }, 1000);
      } catch (e) {
        this.showPhraseError();
        this.isFetching = false;
      }
    },
    async fetchSelectedVariantLocaleFromPhraseCommitProxy() {
      this.isFetching = true;
      try {
        await this.fetchSelectedVariantLocaleFromPhraseCommit(this.response.data.new_phrase_tree);
        this.isFetching = false;
      } catch (e) {
        this.showPhraseError();
        this.isFetching = false;
      }
    },
    showPhraseError() {
      this.showWarning({
        title: 'An error occured',
        text: 'An error occurred while fetching texts from Phrase and storing in the variant.',
        variant: 'danger',
      });
    },
    getPhraseValue(location) {
      if (location in this.selectedVariantPhrases) {
        if (this.selectedVariantPhrases[location].elem.newText !== undefined) {
          return this.selectedVariantPhrases[location].elem.newText || '';
        }
        return this.selectedVariantPhrases[location].elem.text || '';
      }
      return '';
    },
    getPhraseApproved(location) {
      const elem = this.selectedVariantPhrases[location]?.elem;
      return elem?.newApproved !== undefined ? elem.newApproved : (elem?.approved || false);
    },
    getPhraseApprovedDirty(location) {
      const elem = this.selectedVariantPhrases[location]?.elem;
      const ret = (elem?.newApproved || false) !== (elem?.approved || false);
      return ret;
    },
    getPhraseTextDirty(item, newText) {
      const elem = this.selectedVariantPhrases[item.location]?.elem;
      if (newText !== undefined) {
        return elem?.text !== newText;
      }
      const ret = elem?.newText !== undefined && elem?.newText !== elem?.text;
      return ret;
    },
    getPhraseDirty(item, newText) {
      return this.getPhraseApprovedDirty(item.location) || this.getPhraseTextDirty(item, newText);
    },
    discardChanges(phraseKey, phrase) {
      const elem = this.selectedVariantPhrases[phraseKey]?.elem;
      this.setSingleVariantPhrase({ phraseKey, phrase, text: elem?.text });
      this.setSingleVariantApproved({ phraseKey, phrase, approved: elem.approved });
      this.updateDirty(phrase);
    },
    toggleDetails(row) {
      this.$set(this.rowDetails, row.location, !row._showDetails);
    },
    toggleAllDetails() {
      this.showAllDetails = !this.showAllDetails;
      this.rowDetails = {};
    },
    addPhraseToVariantResponse(phrase, item) {
      const formattedPhrase = `[phrase:${phrase.id}]`;
      const text = item.phraseValue + formattedPhrase;
      this.variantPhraseApproved(item.location, item, false);
      this.variantPhraseChange(item.location, item, text);
    },
    removePhraseFromVariantResponse(index, item) {
      const copy = cloneDeep(this.getTextBlocks(item.phraseValue));
      copy.splice(index, 1);
      const finalText = copy.map((e) => e.value).join('');
      this.variantPhraseApproved(item.location, item, false);
      this.variantPhraseChange(item.location, item, finalText);
    },
    getTextBlocks(text) {
      return text ? mapPhrases(text) : [{ value: '', isPhrase: false }];
    },
    getFormattedPhrasePath(path) {
      let formattedPath = '';
      for (let i = 0; i < path.length; i++) {
        formattedPath += UUID_REGEX.test(path[i]) ? this.phrasesDict[path[i]].name : path[i];
        formattedPath += i < path.length - 1 ? ' > ' : '';
      }
      return formattedPath;
    },
    getVariantPhraseValue(item) {
      const id = this.phrase2Id(item.value);
      const key = Object.keys(this.masterPhrases).find((path) => path.includes(id));
      return this.getPhraseValue(key) || ' ';
    },
    updateOrder(update, item) {
      const copy = cloneDeep(this.getTextBlocks(item.phraseValue));
      copy.splice(update.moved.oldIndex, 1);
      copy.splice(update.moved.newIndex, 0, update.moved.element);
      const finalText = copy.map((e) => e.value).join('');
      this.variantPhraseApproved(item.location, item, false);
      this.variantPhraseChange(item.location, item, finalText);
    },
    getPreview(item) {
      return this.getTextBlocks(item.phraseValue).map((e) => (e.isPhrase ? this.getVariantPhraseValue(e) : e.value)).join('');
    },
    filteredText(value) {
      return this.wysiwygEnabled ? formatTextWithLinebreaks(value) : value;
    },
    updateDirty(item, newText) {
      this.$set(this.isDirty, item.location, this.getPhraseDirty(item, newText));
    },
    touch(item, v) {
      // Notice that the two touch functions differ since we don't update the vuex store's text
      // upon every key stroke but wait until blurring the field.
      this.updateDirty(item, v);
    },
    touchApproveButton(item, approved) {
      this.variantPhraseApproved(item.location, item, approved);
      this.updateDirty(item);
    },
    translateVariantPhrase(item) {
      this.translateVariantPhraseKey(item.location);
      this.touch(item, null);
    },
  },
};
</script>

<style scoped>
.save-row{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
}
::v-deep .cursor-auto{
  cursor:auto !important;
}
::v-deep table > tbody > tr.dirty-row > td:first-child {
  box-shadow: 5px 0 0 0 var(--warning) inset;
}
::v-deep .table-details{
  overflow-x: hidden;
}
.cursor-move{
  cursor:move;
}

</style>
