<template>
  <div>
    <b-row>
      <b-col
        class="my-auto"
        cols="auto"
      >
        <b-dropdown
          text="Filter by bot"
          size="sm"
          class="m-0 p-0"
          menu-class="bg-white py-0 border filter-dropdown"
        >
          <b-dropdown-form form-class="p-2">
            <label>
              <font-awesome-icon icon="info-circle" />
              This filter applies only to node classifiers.
            </label>
            <b-button-group
              class="w-100"
              size="sm"
            >
              <b-button @click="selectAllBotFilter">
                All
              </b-button>
              <b-button @click="selectNoneBotFilter">
                None
              </b-button>
            </b-button-group>
          </b-dropdown-form>
          <b-dropdown-divider />
          <b-dropdown-form form-class="p-2">
            <b-form-checkbox
              :checked="selectedBotFilter.includes(null)"
              class="mb-2"
              @change="toggleSelectNoRelated"
            >
              Unassigned
              <tooltipped-text
                value="Classifiers that do not have related bot assigned"
              />
            </b-form-checkbox>
            <b-form-checkbox-group
              v-model="selectedBotFilter"
              :options="botFilterOptions"
              stacked
            />
          </b-dropdown-form>
        </b-dropdown>
      </b-col>
      <b-col class="my-1 pl-0">
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              placeholder="Search classifier by name"
            />

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      show-empty
      empty-text="There are currently no models"
      :tbody-tr-attr="{ style: 'cursor:pointer' }"
      :items="models"
      :fields="modelFields"
      no-caret
      hover
      sort-by="name"
      :filter-included-fields="['name']"
      :filter="filter"
      @row-clicked="onRowClicked"
    >
      <template #head(language)>
        <b-dropdown
          v-b-tooltip.hover.viewport.noninteractive="'Click to filter'"
          toggle-class="p-0 font-weight-bold custom-header"
          variant="text"
          class="m-0 p-0"
          menu-class="bg-white py-0 border"
          no-caret
        >
          <template #button-content>
            Language
            <font-awesome-icon icon="filter" />
          </template>
          <b-dropdown-form form-class="p-2">
            <b-form-checkbox-group
              v-model="selectedLanguages"
              :options="languageFilterOptions"
              stacked
            />
          </b-dropdown-form>
        </b-dropdown>
      </template>
      <template #head(typeText)>
        <b-dropdown
          v-b-tooltip.hover.viewport.noninteractive="'Click to filter'"
          toggle-class="p-0 font-weight-bold custom-header"
          variant="text"
          class="m-0 p-0"
          no-caret
          menu-class="bg-white py-0 border"
        >
          <template #button-content>
            Type
            <font-awesome-icon icon="filter" />
          </template>
          <b-dropdown-form form-class="p-2">
            <b-form-checkbox-group
              v-model="selectedClassifierTypes"
              :options="classifierTypes"
              stacked
            />
          </b-dropdown-form>
        </b-dropdown>
      </template>
      <template #cell(permitted)="row">
        <font-awesome-icon
          v-if="row.item.permitted"
          icon="check"
        />
        <font-awesome-icon
          v-else
          icon="lock"
        />
      </template>
      <template #cell(autoTrain)="row">
        <font-awesome-icon
          size="lg"
          :color="autoTrainColor(row.item)"
          :icon="autoTrainIcon(row.item)"
        />
      </template>

      <template #cell(trainingColumn)="{ item: { trainingColumn } }">
        <b-container
          v-if="hasVariants"
          no-gutters
          class="pd-0"
        >
          <b-row
            v-for="({
              text, name, language, icon: { icon, color }, id,
            }, index) in trainingColumn"
            :key="index"
          >
            <b-col cols="4">
              <template v-if="trainingColumn.length > 1">
                <div
                  v-if="language"
                  v-b-popover.hover="name"
                  class="d-inline"
                  @click="e=>onRowClicked(getGlobalNLUModels[id], null, e)"
                >
                  {{ language }}:
                </div>
                <template v-else>
                  {{ name }}:
                </template>
              </template>
            </b-col>
            <b-col cols="8">
              <font-awesome-icon
                class="mr-2"
                size="lg"
                :icon="icon"
                :style="{ color }"
              />
              {{ text }}
            </b-col>
          </b-row>
        </b-container>
        <template v-else>
          <font-awesome-icon
            class="mr-2"
            size="lg"
            :icon="trainingColumn[0].icon.icon"
            :style="{ color: trainingColumn[0].icon.color }"
          />
          {{ trainingColumn[0].text }}
        </template>
      </template>
      <template #cell(buttons)="row">
        <b-button
          v-b-tooltip.hover
          size="sm"
          variant="outline-warning"
          title="Archive Classifier"
          @click="() => promptArchiveClassifier(row.item.id, row.item.name)"
        >
          <font-awesome-icon icon="archive" />
        </b-button>
        <b-button
          v-b-tooltip.hover
          size="sm"
          variant="outline-danger"
          title="Delete Classifier"
          @click="() => promptDeleteClassifier(row.item.id, row.item.name)"
        >
          <font-awesome-icon icon="trash-alt" />
        </b-button>
      </template>
    </b-table>

    <b-row
      class="mt-3 ml-1"
    >
      <b-button
        v-b-modal.new-clf-modal
        variant="primary"
      >
        <font-awesome-icon icon="plus" /> Create classifier
      </b-button>
      <b-button
        v-b-modal.classifier-upload-modal
        class="mx-2"
        variant="secondary"
      >
        <font-awesome-icon icon="upload" /> Upload Classifier
      </b-button>
      <b-btn
        variant="info"
        :disabled="isModelsLoading"
        @click="fetchGlobalNLUModels()"
      >
        <template v-if="isModelsLoading">
          <b-spinner small />
          Refreshing
        </template>
        <template v-else>
          <font-awesome-icon icon="sync" /> Refresh list
        </template>
      </b-btn>
    </b-row>

    <b-modal
      id="new-clf-modal"
      title="Create classifier"
      ok-title="Create"
      :ok-disabled="$v.$invalid"
      @ok="createClassifierProxy"
    >
      <b-form-group
        label="Name"
        label-for="botClassifierNameForm"
      >
        <b-form-input
          id="botClassifierNameForm"
          v-model="newClassifierName"
          type="text"
          :state="$v.newClassifierName.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.newClassifierName.required">
            Your model must have a name
          </div>
        </b-form-invalid-feedback>
        <b-form-invalid-feedback>
          <div v-if="!$v.newClassifierName.uniqueName">
            Your model name must be unique
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Description"
        label-for="botClassifierDescriptionForm"
      >
        <b-form-textarea
          id="botClassifierDescriptionForm"
          v-model="newClassifierDescription"
        />
      </b-form-group>
      <b-form-group
        label="Language"
      >
        <b-form-select
          v-model="newClassifierLanguage"
          :options="LanguageOptions"
          :state="$v.newClassifierLanguage.$invalid ? false : null"
        />
        <b-form-invalid-feedback>
          <div v-if="!$v.newClassifierLanguage.required">
            Your classifier must specify a langauge.
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <PermissionsSelector ref="clfPermissions" />
    </b-modal>

    <classifier-upload />

    <b-modal
      :visible="showClassifierUsageModal"
      ok-only
      @change="toggleClassifierUsageModal"
    >
      The classifier could not be deleted because it is used in a staged or an unstaged bot.

      <p v-if="unstagedBotsUsage">
        Usages in unstaged bots:
        <ul>
          <li
            v-for="[botId, botName] in Object.entries(unstagedBotsUsage)"
            :key="botId"
          >
            <b-link :to="linkObject(botId)">
              {{ botName }}
            </b-link>
          </li>
        </ul>
      </p>
      <p v-if="stagedBotsUsage">
        Usages in staged bots:
        <ul>
          <li
            v-for="[botId, botName] in Object.entries(stagedBotsUsage)"
            :key="botId"
          >
            {{ botName }} - {{ botId }}
          </li>
        </ul>
      </p>
    </b-modal>
  </div>
</template>
<script>

import { mapGetters, mapMutations, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ClassifierUpload from '@/pages/NLU/ModelsOverview/ClassifierUpload.vue';
import { LanguageOptions } from '@/js/constants';
import TooltippedText from '@/components/TooltippedText.vue';
import PermissionsSelector from '@/components/PermissionsSelector.vue';

export default {
  name: 'Classifiers',
  components: { TooltippedText, ClassifierUpload, PermissionsSelector },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      showClassifierUsageModal: false,
      stagedBotsUsage: [],
      unstagedBotsUsage: [],
      newClassifierName: '',
      newClassifierDescription: '',
      newClassifierLanguage: null,
      LanguageOptions,
      // filters
      filter: '',
      classifierTypes: ['Node classifier', 'Uploaded', 'Trainable classifier', 'AI Engine'],
      selectedClassifierTypes: ['Node classifier', 'Uploaded', 'Trainable classifier', 'AI Engine'],
      selectedLanguages: LanguageOptions.map((e) => e.value).concat([null, '']),
      selectedBotFilter: [],
    };
  },
  computed: {
    ...mapGetters('nlu/classifier', [
      'getGlobalNLUModels',
      'isModelsLoading',
    ]),
    ...mapGetters('botManipulation', ['botsList']),
    modelFields() {
      return [
        {
          key: 'permitted',
          label: 'Permission',
          class: 'text-center permission-col',
        },
        {
          key: 'name',
          label: 'Name',
          tdClass: 'align-middle font-weight-bold',
          sortable: true,
        },
        {
          key: 'description',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'autoTrain',
          label: 'Auto Train',
          tdClass: 'align-middle',
        },
        {
          key: 'language',
          label: 'Language',
          formatter: this.getLanguage,
          sortable: true,
        },
        {
          key: 'typeText',
          label: 'Type',
          tdClass: 'align-middle keep-all',
          sortable: true,
        },
        {
          key: 'trainingColumn',
          label: 'Training State',
          tdClass: 'align-middle keep-all',
          sortable: true,
        },
        {
          key: 'buttons',
          label: '',
          thClass: 'text-right',
          tdClass: 'text-right align-middle',
        },
      ];
    },
    botFilterOptions() {
      return this.botsList ? this.botsList.filter((e) => !(e.hidden))
        .map((e) => ({ text: e.name, value: e.id })) : [];
    },
    models() {
      const variants = Object.values(this.getGlobalNLUModels).filter(
        (x) => x.mainClassifier !== null);

      let models = Object.values(this.getGlobalNLUModels);
      models = models.map((e) => ({ ...e, typeText: e.typeText || 'AI Engine' }));
      models = models.filter(
        (x) => x.mainClassifier === null
        && this.selectedClassifierTypes.includes(x.typeText)
        && this.selectedLanguages.includes(x.language)
        && this.selectedBotFilter.includes(x.config.bot)
        ,
      );

      const trainingStates = Object.fromEntries(models.map((x) => [x.id, []]));
      for (const variant of variants) {
        if (variant.mainClassifier in trainingStates) {
          trainingStates[variant.mainClassifier].push({
            ...this.getTrainingState(variant),
            language: variant.language,
            name: variant.name,
            id: variant.id,
          });
        }
      }
      for (const model of models) {
        const state = trainingStates[model.id];
        const name = state.length === 0 ? '' : 'Main';
        state.unshift({
          ...this.getTrainingState(model),
          language:
          model.language,
          name,
          id: model.id,
        });
        model.trainingColumn = state;
      }
      return models;
    },
    hasVariants() {
      return Object.values(this.getGlobalNLUModels).findIndex(
        (x) => x.mainClassifier !== null,
      ) > -1;
    },
    languageFilterOptions() {
      return [{ text: 'main', value: null },
        { text: 'No language', value: '' }].concat(this.LanguageOptions);
    },
  },
  mounted() {
    this.selectAllBotFilter();
  },
  methods: {
    ...mapMutations('nlu/classifier', [
      'toggleModelDetails',
    ]),
    ...mapActions('nlu/classifier', [
      'fetchGlobalNLUModels',
      'deleteClassifier',
      'createClassifier',
      'deleteClassifierVersion',
      'archiveClassifier',
    ]),
    ...mapActions('sidebar', ['showWarning']),
    toggleSelectNoRelated() {
      if (this.selectedBotFilter.includes(null)) {
        this.selectedBotFilter = this.selectedBotFilter.filter((e) => e !== null);
      } else {
        this.selectedBotFilter = this.selectedBotFilter.concat([null]);
      }
    },
    selectAllBotFilter() {
      this.selectedBotFilter = [null]
        .concat(this.botFilterOptions.map((e) => e.value));
    },
    selectNoneBotFilter() {
      this.selectedBotFilter = [];
    },
    getLanguage(value) {
      return this.LanguageOptions.find((e) => e.value === value)?.text;
    },
    getTrainingState(model) {
      let text = '';
      let icon = {};
      // The training state is only set if there are at least one training.
      if (!model.trainingState || model.trainingState.finished === undefined) {
        text = 'Not trained yet';
        icon = { icon: 'thermometer-empty', color: 'black' };
      } else if (model.trainingState.finished) {
        if (model.trainingState.success) {
          text = 'Completed';
          icon = { icon: 'check-circle', color: 'green' };
        } else {
          text = 'Failed';
          icon = { icon: 'times-circle', color: 'red' };
        }
      } else if (model.trainingState.started) {
        text = 'Training...';
        icon = { icon: 'running', color: 'blue' };
      } else {
        text = 'In queue...';
        icon = { icon: 'align-justify', color: 'blue' };
      }
      return { text, icon };
    },
    onRowClicked(item, index, event) {
      if (item.permitted) {
        this.openCtrlLink({ name: 'nlu-model-page', params: { modelId: item.id } }, event);
      } else {
        this.showWarning({
          title: 'Permission denied',
          text: 'You do not have permission to open this classifier.',
          variant: 'warning',
        });
      }
    },
    async createClassifierProxy() {
      const { assignYourself, botPermissions, groupPermissions } = this.$refs.clfPermissions;
      const data = {
        name: this.newClassifierName,
        description: this.newClassifierDescription,
        type: 'swml.bot',
        language: this.newClassifierLanguage,
        permissions: {
          assign_user: assignYourself,
          groups: groupPermissions,
          bots: botPermissions,
        },
      };
      this.modelId = await this.createClassifier(data);
    },
    async promptDeleteClassifier(classifierId, classifierName) {
      const question = `Are you sure you want to delete the model "${classifierName}"?`;
      if (await this.$bvModal.msgBoxConfirm(question)) {
        const response = await this.deleteClassifier({ classifierId });
        if (response.success) {
          await this.fetchGlobalNLUModels();
          await this.$bvModal.msgBoxOk('Model successfully deleted');
        } else if (response.error) {
          await this.fetchGlobalNLUModels();
          await this.$bvModal.msgBoxOk('An unexpected error occurred.');
        } else {
          this.unstagedBotsUsage = response.unstaged_usage;
          this.stagedBotsUsage = response.staged_usage;
          this.toggleClassifierUsageModal(true);
        }
      }
    },
    async promptDeleteClassifierVersion(versionId, classifierName, versionName) {
      const question = `Are you sure you want to delete the version "${versionName}" of `
            + `"${classifierName}"?`;
      if (await this.$bvModal.msgBoxConfirm(question)) {
        const response = await this.deleteClassifierVersion({ versionId });
        if (response.success) {
          await this.fetchGlobalNLUModels();
          await this.$bvModal.msgBoxOk('Model version successfully deleted');
        } else if (response.error) {
          await this.fetchGlobalNLUModels();
          await this.$bvModal.msgBoxOk('An unexpected error occurred.');
        } else {
          this.unstagedBotsUsage = response.unstaged_usage;
          this.stagedBotsUsage = response.staged_usage;
          this.toggleClassifierUsageModal(true);
        }
      }
    },
    async promptArchiveClassifier(classifierId, classifierName) {
      const question = `Are you sure you want to archive the model "${classifierName}"?`;
      if (await this.$bvModal.msgBoxConfirm(question)) {
        const response = await this.archiveClassifier({ classifierId });
        if (response.success) {
          await this.fetchGlobalNLUModels();
          await this.$bvModal.msgBoxOk('Model successfully archived');
        } else if (response.error) {
          await this.fetchGlobalNLUModels();
          await this.$bvModal.msgBoxOk('An unexpected error occurred.');
        } else {
          this.unstagedBotsUsage = response.unstaged_usage;
          this.stagedBotsUsage = [];
          this.toggleClassifierUsageModal(true);
        }
      }
    },
    toggleClassifierUsageModal(value) {
      if (!value) {
        this.stagedBotsUsage = [];
        this.unstagedBotsUsage = [];
      }
      this.showClassifierUsageModal = value;
    },
    autoTrainIcon(item) {
      if (item.typeText === 'Uploaded') {
        return 'ban';
      }
      if (item.autoTrain) {
        return 'check-circle';
      }
      return 'times-circle';
    },
    autoTrainColor(item) {
      if (item.typeText === 'Uploaded') {
        return 'black';
      }
      if (item.autoTrain) {
        return 'green';
      }
      return 'orange';
    },
    linkObject(botId) {
      if (!botId.includes('/')) {
        return { name: 'flow', params: { botId } };
      }
      const ids = botId.split('/');
      return { name: 'edit-variant', params: { botId: ids[0], variantId: ids[1] }, hash: '#classifiers' };
    },
  },
  validations: {
    newClassifierName: {
      required,
      uniqueName(value) {
        const nluModels = this.getGlobalNLUModels;
        const nluModelNames = Object.values(nluModels).map((nluModel) => nluModel.name);
        return !nluModelNames.includes(value);
      },
    },
    newClassifierLanguage: { required },
  },
};
</script>
<style scoped>
::v-deep .keep-all{
  word-break: keep-all;
  white-space: nowrap;
}
::v-deep .custom-header{
  font-size: 0.82rem;
}
::v-deep .filter-dropdown{
  max-height: 300px;
  min-width: 310px;
  overflow-y: auto;
}
</style>
