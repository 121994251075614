<template>
  <div>
    <b-row no-gutters>
      <b-col
        class="mt-3 px-sm-0 pr-lg-2"
        cols="12"
        lg="6"
      >
        <b-card
          title="Category"
          class="r-75"
          body-class="p-3"
          style="height: 100%;"
        >
          <b-card-sub-title v-if="mainCategories.length == 0">
            No categories available. <br>
            Create a category by clicking "Add category" below
          </b-card-sub-title>
          <b-card-sub-title v-else>
            Click to choose a category for datapoint
          </b-card-sub-title>
          <div class="mt-4">
            <b-btn
              v-for="{ id, name } in mainCategories"
              :key="`category-${id}`"
              :variant="categoryIdToVariant(id)"
              class="m-1"
              @click="() => toggleCategory(id)"
            >
              {{ name }}
            </b-btn>
          </div>
        </b-card>
      </b-col>
      <b-col
        class="mt-3 px-sm-0 pl-lg-2"
        cols="12"
        lg="6"
      >
        <b-card
          title="Subcategory"
          class="r-75"
          body-class="p-3"
          style="height: 100%;"
        >
          <b-card-sub-title v-if="noCategoriesChosen">
            In order to choose a subcategory you must first choose a parent category
          </b-card-sub-title>
          <b-card-sub-title v-else-if="subcategoriesToShow.length === 0">
            There are no subcategories for the chosen category. <br>
            Click "Add Subcategory" to add one
          </b-card-sub-title>
          <b-card-sub-title v-else>
            Click to choose subcategory for data point
          </b-card-sub-title>
          <div
            v-for="parentCategory in subcategoriesToShow"
            :key="`parent-category-${parentCategory.id}`"
          >
            <h5
              class="text-muted mt-4"
            >
              {{ parentCategory.name }}
            </h5>
            <b-btn
              v-for="{ id, name } in parentCategory.children"
              :key="id"
              :variant="categoryIdToVariant(id)"
              class="mx-1 mb-2"
              @click="() => toggleCategory(id)"
            >
              {{ name }}
            </b-btn>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-card
      class="r-75 mt-3"
      body-class="p-3"
    >
      <completion-input
        ref="autocomplete"
        class="d-inline-block"
        :completions="completions"
        value=""
        @input="(id) => toggleCategory(id)"
      />
      <b-btn
        class="ml-2"
        variant="primary"
        :disabled="saving || noCategoriesChosen"
        @click="submitLabels"
      >
        Submit
        <template v-if="saving">
          <b-spinner small />
        </template>
      </b-btn>
      <b-btn-group class="ml-2">
        <b-btn
          variant="primary"
          @click="addCategory"
        >
          Add Category
        </b-btn>
        <b-btn
          variant="primary"
          @click="addSubcategory"
        >
          Add Subcategory
        </b-btn>
      </b-btn-group>
      <div class="mt-2">
        <small class="text-muted">
          Pro-Tip: In dropdown press "Enter" to choose category and then press "Tab-Enter" to submit
        </small>
      </div>
      <b-form-checkbox
        v-model="autoFocusProxy"
        style="z-index:0"
        class="mt-2"
        switch
      >
        Autofocus cursor in search-field when loading next datapoint
      </b-form-checkbox>
      <b-form-checkbox
        v-model="forceRandomProxy"
        style="z-index:0"
        class="mt-2"
        switch
      >
        Use random sampling instead of smart sampling
      </b-form-checkbox>
    </b-card>
    <data-exploration-new-category ref="newCategory" />
  </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import {
  mapState,
  mapGetters,
  mapActions,
  mapMutations,
} from 'vuex';
import CompletionInput from 'supwiz/components/CompletionInput.vue';
import { addThisArgs, applyThisArgs } from '@/js/storeHelpers';
import endpoints from '@/js/urls';
import DataExplorationNewCategory from '@/pages/DataExploration/DataExplorationLabeling/DataExplorationNewCategory.vue';

export default {
  name: 'DataExplorationAssignLabels',
  components: {
    DataExplorationNewCategory,
    CompletionInput,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchData(to.params.dataId, to.params.dataType);
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.dataId !== from.params.dataId || to.params.dataType !== from.params.dataType) {
      this.fetchData(to.params.dataId, to.params.dataType);
    }
    next();
  },
  props: {
    datasetId: {
      type: Number,
      required: true,
    },
    dataId: {
      type: Number,
      required: true,
    },
    alreadyAssignedLabels: {
      type: Array,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    // If true PUT is used when submitting assignment. When false, a POST operation is used
    // (thereby creating NEW labels).
    submitShouldUpdateExistingLabels: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Sets are not supported in Vue yet so we use dicts instead.
      chosenMainCategories: {},
      chosenSubcategories: {},
      hasFetchedData: false,
      text: null,
      saving: false,
    };
  },
  computed: {
    ...mapState('dataExploration/categories', ['categories', 'autoFocus']),
    ...mapGetters('dataExploration/categories', [
      'getCategoryDepth',
      'getSubcategoriesFromParents',
      'getSubcategoriesForParent',
      'getCategoryById',
      'nameOfId',
    ]),
    ...mapGetters('dataExploration/categories', {
      mainCategories: 'getMainCategories',
    }),
    ...mapGetters('dataExploration', [
      'forceRandomSample',
    ]),
    ...applyThisArgs(mapGetters('dataExploration', {
      dataset: 'getDatasetById',
    }), 'datasetId'),
    subcategoriesToShow() {
      return this.getSubcategoriesFromParents(this.chosenMainCategories);
    },
    noCategoriesChosen() {
      return Object.keys(this.chosenMainCategories).length === 0;
    },
    chosenCategories() {
      return { ...this.chosenMainCategories, ...this.chosenSubcategories };
    },
    completions() {
      return Object.values(this.categories).filter(
        ({ id }) => !(id in this.chosenCategories),
      ).map(
        ({ id }) => ({ key: id, value: this.nameOfId(id) }),
      );
    },
    autoFocusProxy: {
      get() {
        return this.autoFocus;
      },
      set(newValue) {
        this.setAutoFocus(newValue);
      },
    },
    forceRandomProxy: {
      get() {
        return this.forceRandomSample;
      },
      set(newValue) {
        this.setForceRandomSample(newValue);
      },
    },
  },
  created() {
    // This cannot be moved to data function, since it uses getter getCategoryById which is not on
    // 'this' when data-function is run.
    // Feed alreadyAssignedLabels into data
    const chosenMainCategoriesTemp = {};
    const chosenSubcategoriesTemp = {};
    this.alreadyAssignedLabels.forEach((categoryId) => {
      const category = this.getCategoryById(categoryId);
      if (category.parentId !== null) {
        // categoryId refers to a subcategory
        chosenMainCategoriesTemp[category.parentId] = category.parentId;
        chosenSubcategoriesTemp[categoryId] = categoryId;
      } else {
        // categoryId refers to a (parent)category
        chosenMainCategoriesTemp[categoryId] = categoryId;
      }
    });
    this.chosenMainCategories = chosenMainCategoriesTemp;
    this.chosenSubcategories = chosenSubcategoriesTemp;
  },
  mounted() {
    if (this.autoFocusProxy) {
      document.getElementsByClassName('autocomplete-input')[0].focus();
    }
  },
  methods: {
    ...mapMutations('dataExploration/categories', [
      'setAutoFocus',
    ]),
    ...mapMutations('dataExploration', [
      'setForceRandomSample',
    ]),
    ...addThisArgs(mapActions('dataExploration/categories', [
      'createCategory',
    ]), { datasetId: 'datasetId' }),
    async addCategory() {
      const newCategory = await this.$refs.newCategory.getNewCategory(this.datasetId, false);
      if (newCategory) {
        const catId = await this.createCategory(newCategory);
        this.$set(this.chosenMainCategories, catId, true);
      }
    },
    async addSubcategory() {
      const chosenCats = Object.keys(this.chosenMainCategories);
      let parentId = chosenCats.length === 1 ? chosenCats[0] : null;
      const newCategory = await this.$refs.newCategory.getNewCategory(
        this.datasetId, true, parentId,
      );
      if (newCategory) {
        const catId = await this.createCategory(newCategory);
        parentId = this.getCategoryById(catId).parentId;
        this.$set(this.chosenMainCategories, parentId, true);
        this.$set(this.chosenSubcategories, catId, true);
      }
    },
    async submitLabels() {
      const allIds = Object.keys(this.chosenMainCategories);
      for (const categoryId of Object.keys(this.chosenSubcategories)) {
        const { parentId } = this.categories[categoryId];
        if (Object.prototype.hasOwnProperty.call(this.chosenMainCategories, parentId)) {
          allIds.push(categoryId);
        }
      }
      this.saving = true;
      const data = {
        data_id: this.dataId,
        data_type: this.dataType,
        categories: allIds,
        dataset_id: this.datasetId,
      };
      const config = { headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` } };
      if (this.submitShouldUpdateExistingLabels) {
        await axios.put(endpoints.dataExplorationLabel, data, config);
      } else {
        await axios.post(endpoints.dataExplorationLabel, data, config);
      }
      this.saving = false;
      this.$emit('submitted');
    },
    categorySetFromId(categoryId) {
      const isMainCategory = this.getCategoryDepth(categoryId) === 0;
      if (isMainCategory) {
        return this.chosenMainCategories;
      }
      return this.chosenSubcategories;
    },
    isSelected(categoryId) {
      const categorySet = this.categorySetFromId(categoryId);
      return Object.prototype.hasOwnProperty.call(categorySet, categoryId);
    },
    categoryIdToVariant(categoryId) {
      return this.isSelected(categoryId) ? 'info' : 'outline-info';
    },
    toggleCategory(categoryId) {
      const category = this.getCategoryById(categoryId);
      const categorySet = this.categorySetFromId(categoryId);
      if (Object.prototype.hasOwnProperty.call(categorySet, categoryId)) {
        Vue.delete(categorySet, categoryId);
        if (!category.parentId) {
          const subCats = this.getSubcategoriesForParent(categoryId);
          subCats.forEach((x) => (
            x.id in this.chosenSubcategories ? Vue.delete(this.chosenSubcategories, x.id) : null
          ));
        }
      } else {
        Vue.set(categorySet, categoryId, true);
        if (category.parentId) {
          Vue.set(this.chosenMainCategories, category.parentId, true);
        }
      }
    },
    async fetchData(dataId, dataType) {
      const url = `${endpoints.dataExplorationData + this.datasetId}/`;
      const config = {
        headers: { Authorization: `JWT ${this.$store.state.auth.jwt}` },
        params: { data_id: dataId, data_type: dataType },
      };
      const { data } = await axios.get(url, config);
      this.text = data.text;
      this.hasFetchedData = true;
    },
  },
};
</script>
